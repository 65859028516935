import React, { useRef } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { urlWebsite } from '../../../../config';
import { FaFilePdf, FaLink } from 'react-icons/fa';
import { FiCopy, FiMail } from 'react-icons/fi';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import duplicate from './modules/duplicate';
import { useSearch } from 'src/hooks/Search';
import { Form } from '@unform/web';
import Input from 'src/components/Forms/Input';
import { useModal } from 'src/hooks/Modal';
import html2pdf from 'html2pdf.js';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import contactModule from './modules/ContactModule';
import printer from './modules/PDFModule';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const Dashboard: React.FC = () => {
  const endpoint = '/speakers_terms_response';
  const title = 'Termos enviados';

  const { addModal, removeModal } = useModal();
  const { reloadSearchAll } = useSearch();
  const { addToast } = useToast();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },
    term_id: { ref: 'term_id', column: 'term_id', label: 'Hash', show: false },
    term_title: { ref: 'term_title', column: 'term_title', label: 'Termo' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'E-mail' },
    language: { ref: 'language', column: 'language', label: 'Idioma' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
    speaker_id: {
      ref: 'speaker_id',
      column: 'speaker_id_response',
      includeColumn: 'name',
      label: 'Palestrante',
    },
    allow_update: { ref: 'allow_update', column: 'allow_update', label: 'Permitir Atualização' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF', show: false },
    status: { ref: 'status', column: 'status', label: 'Status do Aceite' },
    approvedAt: {
      ref: 'ApprovedAt',
      column: 'ApprovedAt',
      label: 'Assinado em',
      mask: 'date',
    },

    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Criado em',
      mask: 'date',
    },
  };




  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    email: { model: 'input', type: 'text', name: 'email', label: 'E-mail' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },
    term_id: {
      alias: 'term_id',
      model: 'selectApi',
      name: 'term_id',
      label: 'SpeakersTerm',
      endpoint,
    },
    speaker_id: {
      alias: 'speaker_id',
      model: 'selectApi',
      name: 'speaker_id',
      label: 'Speaker',
      endpoint,
    },
    language: {
      model: 'selectSimple',
      name: 'language',
      label: 'Idioma',
      defaultValue: 'ptBR',
      options: [
        { label: 'Português', value: 'ptBr' },
        { label: 'Inglês', value: 'en' },
        { label: 'Espanhol', value: 'es' },
      ],

    },
    allow_update: {
      model: 'selectSimple',
      name: 'allow_update',
      label: 'Permitir atualização?',
      defaultValue: 'Permitir Atualização?',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ],
    },
  };

  const formSchemaUpdate = {
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'O palestrante irá participar?',


      options: [
        { label: 'Aguardando confirmação', value: 'Aguardando confirmação' },
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ],
    },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'name',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };
  const contentRef = useRef<HTMLDivElement>(null); // Create a reference to the content div




  async function gerarPDF() {

    const content = contentRef.current;
    if (!content) return;




    const pdf = new jsPDF('p', 'pt', 'a4'); // 'pt' units, and 'a4' page size


    pdf.html(content, {
      callback: function (pdf) {
        pdf.save('generated.pdf');
      },
      x: 10,
      y: 10,
      width: 190, // Control the width of rendering in the PDF
      windowWidth: 650 // Match this to your screen width for accurate layout
    });


  }


  const generatePDF = async (hash, email = '') => {

    try {
      const item = await api.post(`/terms-send-invite-pdf/${hash}`, { email, replyTo: "bc@bariatricchannel.com.br", emailOwner: 'Bariatric Channel' });

      if (contentRef.current) {
        contentRef.current.innerHTML = item?.data?.content;
      }
      gerarPDF()

      removeModal('modal')
      return;
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao enviar' });
      return;
    }
  }


  const sendMail = async (hash, email = '') => {

    try {
      await api.post(`/terms-send-invite/${hash}`, { email, replyTo: "bc@bariatricchannel.com.br", emailOwner: 'Bariatric Channel' });
      addToast({ type: 'success', title: 'Enviado' });
      removeModal('modal')
      return;
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao enviar' });
      return;
    }
  }


  const sendTest = (hash) => {

    addModal({
      title: '', key: 'modal', content: <>
        <Form onSubmit={(data) => sendMail(hash, data?.email)}>
          <Input name="email" placeholder="E-mail" />
          <button className='greenButton2'>Enviar</button>
        </Form>

      </>, theme: 'whiteModalMini'
    })


  }

  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [

      (lineData: Record<string, any>) => duplicate({ lineData, reload: reloadSearchAll }),

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) => {
        return <button className="lineIcon" title="Copiar link do termo"><CopyToClipboard Icon={FiCopy} text={`${urlWebsite}/app/${lineData?.project_id}/speaker-invitation/${lineData?.hash}`} /></button>


      },
      (lineData: Record<string, any>) => {
        return <button className="lineIcon" title="Enviar termo por email" type="button" onClick={() => sendMail(lineData.hash)}>
          <FiMail />
        </button>

      },
      (lineData: Record<string, any>) => {
        return <button className="lineIcon" title="Enviar teste por email" type="button" onClick={() => sendTest(lineData.hash)}>
          <FiMail color="blue" />
        </button>

      },
      (lineData: Record<string, any>) => {
        return <button className="lineIcon" title="Gerar PDF" type="button" onClick={() => generatePDF(lineData.hash)}>
          <FaFilePdf color="blue" />
        </button>

      },


    ],
  };

  return (<>
    <Container>
      <SearchComponent {...config} />
    </Container>

    <div ref={contentRef} style={{ padding: '20px', width: '100%', backgroundColor: '#f0f0f0' }} />

  </>
  );
};

export default Dashboard;

import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
 
 
 display: flex ;
 min-height: 100vh;
width: 100%;
flex-direction: row;
align-items: flex-start;
justify-content: center;
position: relative;
.page-creator {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.preview-toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #333;
  color: #fff;
 // padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
}

.preview-mode .sidebar,
.preview-mode .actions {
  display: none;
}

 

.create-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.create-buttons button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-buttons button:hover {
  background-color: #f0f0f0;
}

.create-buttons button span {
  margin-top: 5px;
  font-size: 50%;
}

.sidebar {
  width: 100%;
  max-width: 500px;
  min-width: 400px;
  background-color: #f8f9fa;
  color: #333;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100vh;
}

.sidebar button {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar button:hover {
  background-color: #f0f0f0;
}

.sidebar table {
  width: 100%;
  margin-top: 20px;
}

.sidebar table th, .sidebar table td {
  padding: 5px;
  text-align: left;
}

.sidebar table th {
  background-color: #f8f9fa;
  color: #333;
}

.sidebar table td {
  background-color: #fff;
  color: #333;
}

.sidebar table input, .sidebar table select {
  width: 100%;
}

.container-list-menu {
  background-color: #f8f9fa;
  color: #333;
  padding: 10px;
  max-width: 100%;
  overflow-y: auto;
}

.container-menu-item,
.section-menu-item,
.row-menu-item,
.content-menu-item {
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.container-menu-item:hover,
.section-menu-item:hover,
.row-menu-item:hover,
.content-menu-item:hover {
  background-color: #e9ecef;
}

 
.actions {
  display: flex;
  gap: 5px;
  float: right;
}

.actions > * {
  cursor: pointer;
}

.container,
.section,
.row,
.content-item {
  transition: opacity 0.3s;
}

.container-list-menu .container-menu-item:hover ~ .container,
.container-list-menu .section-menu-item:hover ~ .section,
.container-list-menu .row-menu-item:hover ~ .row,
.container-list-menu .content-menu-item:hover ~ .content-item {
  opacity: 0.5;
}
.container-list {
  flex: 1;
 
  padding: 20px;
  overflow-y: auto;
 
  width: 100%;
// background: rgba(0,0,0,0.1) ;
  //min-height: 50vh;
}

/* Container styling */
.container {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
 // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex ;
  gap: 10px;
  max-width: 1200px;
  width: 100%;
}

/* Section styling */
.section {
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
}

.add-row-button {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.add-row-button:hover {
  opacity: 1;
}

/*
.section button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.section button:hover {
  background-color: #0056b3;
}
*/

/* Row styling */
.row {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
//  cursor: pointer;
}

.row .placeholder {
  width: 100%;
  text-align: center;
  color: #aaa;
}

.row button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.row button:hover {
  background-color: #218838;
}

/* Content item styling */
.content-item {
  margin: 5px;
  padding: 10px;
  background-color: #e9ecef;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  text-align: center;
}

.content-item img, .content-item video {
  max-width: 100%;
  border-radius: 4px;
}

/* Modal styles */
.whiteModalMedium {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.whiteModalMedium button {
  margin: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
  
}

.whiteModalMedium button:hover {
  background-color: #0056b3;
}
 `

interface IEditableDiv {
  computer: Record<string, any>;
  mobile: Record<string, any>;
}


// Função para validar uma propriedade CSS e seu valor
const isValidCSSPropertyValue = (property, value) => {
  // Cria um elemento temporário para testar a propriedade CSS
  const tempElement = document.createElement('div');
  tempElement.style[property] = value;


  // Verifica se o navegador aceita a propriedade e o valor
  return tempElement.style[property];
};

const generateStyles = props => {
  return props && Object.keys(props).map(key => {
    const value = props[key]?.toString();
    if (isValidCSSPropertyValue(key, value)) {
      return css`
        ${key}: ${value} !important;
      `;
    }
    return ''; // Pula valores inválidos
  });
};

export const EditableDiv = styled.div<IEditableDiv>`
display: flex ;
flex-direction: row;
align-items: 'flex-start';
justify-content: flex-start;

${props => generateStyles(props?.computer)}

@media (max-width: 750px) {
  ${props => generateStyles(props?.mobile)}
}

 



`

interface IBackground {
  background: string;
  backgroundPosition: string;
  backgroundOpacity: number;
}

export const BgImageStyle = styled.div<IBackground>`
 
 width: 100%;
 position: absolute ;
 top: 0;
 left: 0;
 bottom: 0;
 right: 0;
 z-index: 0;

 background-position: center !important;
 background-size: cover !important;
 background-repeat : no-repeat !important;

 ${props => props?.background && css`
 
 background: url(${props?.background});
 
 `}
 
 ${props => props?.backgroundPosition && css`
 
 background-position:  ${props?.backgroundPosition};
 
 `}
 ${props => props?.backgroundOpacity && css`
 
opacity:  ${props?.backgroundOpacity};
 
 `}
 `;



export const ContainerListStyle = styled(EditableDiv)`
  flex: 1;
 //padding: 20px;
 overflow-y: auto;
 width: 100%;
background: rgba(0,0,0,0.1) ;
// min-height: 50vh;
 display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
 

  
 `;



export const ContainerStyle = styled(EditableDiv)`
 //  background-color: #fff;
  margin-bottom: 20px;
 // padding: 20px;
 // border: 1px solid #ccc;
  border-radius: 8px;
 // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex ;
  gap: 10px;
  max-width: 1200px;
  width: 100%;

z-index: 1;
/* Container types */
.container-single .section {
  flex: 1;
}

.container-double .section {
  flex: 1 1 50%;
}

.container-triple .section {
  flex: 1 1 33.33%;
}

 `;



export const SectionStyle = styled(EditableDiv)`
  width: 100% ;

 

 `;


export const RowStyle = styled(EditableDiv)`
  
 // background-color: #f9f9f9;
 color: #fff;
  padding: 15px;
  margin-bottom: 10px;
width: 100%;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
 

 `;

export const ButtonStyle = styled.button<IEditableDiv>`
      min-width: 200px;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;

  ${props => props?.computer && Object.keys(props?.computer)?.map(key => {

  return css`
${key} : ${props?.computer?.[key]} !important;

`


})}


@media (max-width: 750px) {
${props => props?.mobile && Object.keys(props?.mobile)?.map(key => {

  return css`
${key} : ${props?.mobile?.[key]} !important;

`


})}
}


 `;


export const ContentStyle = styled(EditableDiv)`
  
  
  margin: 5px;
  padding: 10px;
  background-color: #e9ecef;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  text-align: center;
 

  img,   video {
    width: 100%;
  max-width: 100%;
  border-radius: 4px;
}

 `;


export const TableStyle = styled(EditableDiv)`
  
  
  margin: 5px;
  padding: 10px;
  background-color: #e9ecef;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  text-align: center;

  h2{
    font-size: 18px;
    line-height: 22px;
    ${props => props?.computer?.color && css`
    color : ${props?.computer?.color} !important;
    `}
  }
  h3{
    font-size: 16px;
    line-height: 20px;

    ${props => props?.computer?.color && css`
    color : ${props?.computer?.color} !important;
    `}
  }

  p{
    font-size: 14px;
    line-height: 18px;

    color: #333 !important;
    p,h2,strong{
      color: #333  !important;
    }
  }
 
 table {
    border: 1px solid #ddd;
    border-collapse: collapse;
    width: 100%;

    tr{
      border: 1px solid #ddd;
    }
    

    thead {
      th {
        background: none;
        font-weight: bold;
        padding: 10px;
        font-size: 14px;
        color: #333  !important;
      }
    }

    tbody {
      td {
        background: none;
        font-weight: regular;
        padding: 10px;
        font-size: 14px;
        color: #333  !important;
      }
    }
 }

 ${props => props?.computer?.['text-align'] && css`
 
 thead {
      th {
        text-align: props?.computer?.['text-align'] !important;
      }
    }

    tbody {
      td {
        text-align: props?.computer?.['text-align'] !important;
      }
    }
 
 `}

 `;
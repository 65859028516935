import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../../hooks/Language';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import calculateDiscount from '../../Functions/calculateDiscount';

export interface ICoupon {
    products: Array<Record<string, any>>;
    hash: string;
}

interface IProps {
    coupon?: Record<string, any>;
    setCoupon: Function;
    cart: Array<Record<string, any>>;

}


export function useCheckCuponCode() {
    const { addLoading, removeLoading } = useLoading();




    const checkCuponCode = async (couponValue, projectHash) => {

        const couponV = couponValue?.toUpperCase();

        addLoading({ title: 'Carregando', key: 'loading' })

        try {
            const response = await api.post(`/check-general-coupon/${projectHash}`, {
                hash: couponV,

            });

            const data = response.data;

            if (!data?._id) {
                removeLoading('loading');
                return {};
            }
            removeLoading('loading');

            return response?.data;
        }
        catch (err) {
            removeLoading('loading');
            return {}
        }

    }

    return { checkCuponCode };



}



const DiscountCoupon: React.FC<IProps> = ({
    coupon,
    setCoupon,
    cart

}) => {
    const { projectHash } = useConfiguration();
    const { getTrackingCupom } = useAuth();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<string>(coupon?.hash || '');
    const [valid, setValid] = useState('');




    const checkCupon = async (couponValue) => {

        if (!couponValue) {
            setValid('Preencha o cupom de desconto');
            return;
        }

        const couponV = couponValue?.toUpperCase();

        setValid('');
        setLoading(true);

        try {
            const response = await api.post(`/check-general-coupon/${projectHash}`, {
                hash: couponV,

            });

            const data = response.data;

            if (!data?._id) {
                setValid(translate('Coupon inválido'));
                setLoading(false);
                return;
            }


            setCoupon(response?.data);

            setLoading(false);
        }
        catch (err) {

            setValid(err?.response?.data?.message || err?.response?.data?.error || 'Coupon inválido');
            setLoading(false);
        }





    }

    useEffect(() => {

        const cupomTracking = getTrackingCupom();

        if (cupomTracking && cupomTracking !== value) {
            checkCupon(cupomTracking);
            setValue(cupomTracking)
        }


    }, [])

    const { translate } = useLanguage();

    return <aside className='outerBlock' style={{ margin: '10px auto', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <h2 style={{ margin: ' 0px', fontSize: '14px', width: '100%', color: '#333', borderBottom: '3px solid #A71C20', padding: '5px 0px', }}>{translate('Tenho um cupom de desconto')}</h2>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input style={{ width: '100%', padding: '10px', borderRadius: '0px 5px 5px 0px', border: '1px solid #ddd' }} name='couponValue' onChange={(e) => setValue(e?.target?.value)} value={value} placeholder='' type='text' />
            <button style={{ width: '70px', padding: '10px' }} className="greenButton2 buttonColored" onClick={() => checkCupon(value)} type="button">{translate('Validar')}</button>
        </div>
        {loading ? <p style={{ margin: '5px 0px', color: '#333', textAlign: 'right', width: '100%' }}><FaSpinner className='spin' size={20} style={{ minWidth: '20px', width: '20px', color: '#333', marginRight: '10px' }} />  {translate(`Carregando`)}</p> : <></>}

        {coupon && calculateDiscount(coupon, cart)?.discount > 0 ? <p style={{ margin: '5px 0px', color: 'rgb(0,150,0)', width: '100%', textAlign: 'right' }}>R$ {format(calculateDiscount(coupon, cart)?.discount)} {translate(`de desconto`)}</p> : <></>}
        {valid !== '' ? <p style={{ margin: '5px 0px', color: 'rgb(150,0,0)', textAlign: 'right', width: '100%' }}>  {translate(valid)}</p> : <></>}
    </aside>

}

export default DiscountCoupon;

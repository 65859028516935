import React, { useEffect, useState } from 'react';
import { addHours, getDate, getDay, getMonth } from "date-fns"
import { monthNumber, simpleDateBR, simpleDateBRWithHour, simpleDateUS } from "../../../../../../utils/date";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Chart from '../../../../../../components/ChartModule';
import { FaCalendar, FaChartBar, FaChartPie, FaCheck, FaCreditCard, FaFileExcel, FaFilter, FaTicketAlt } from 'react-icons/fa';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInputSearch';
import { useModal } from '../../../../../../hooks/Modal';
import { FiFilter } from 'react-icons/fi';
import { convertToXLSX } from '../../../../../../utils/download';
import api from '../../../../../../services/api';
import { string } from 'yup';
import { useParams } from 'react-router-dom';
interface IProps {
    sales?: Array<Record<string, any>>,
    salesProducts?: Array<Record<string, any>>,
    students?: Array<Record<string, any>>,
    products?: Array<Record<string, any>>,
}

interface IOption {
    id: string;
    label: string;
    value: string;
}


const convert = (list: Array<Record<string, any>>, group = false) => {

    const items: Array<Array<string>> = [];


    if (group) {
        items.push(['Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Indicado por']);
        list?.map(item => {

            items.push([item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', item?.seller_hash]);

        })
    }
    else {
        items.push(['Produto', 'Transferido?', 'Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Indicado por']);
        list?.map(item => {

            items.push([item?.product, item?.haveClientID, item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', item?.seller_hash]);

        })
    }


    convertToXLSX(items);

}

const ListLastTickets: React.FC<IProps> = ({ sales, salesProducts = [], students, products = [] }) => {



    const list = salesProducts?.reduce((prev, item) => {


        prev.push({ createdAt: item?.createdAt, dateWithHour: simpleDateBRWithHour(item?.createdAt), titular: item?.client_id_response?.name, comprador: item?.payer_id_response?.name, product: item?.product_id_response?.title, payment_status_id: item?.payment_status_id, payment_method_id: item?.payment_method_id });
        return prev;

    }, []);



    const sortedList = list.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);


    const translatePayment = {
        paid: <nav style={{ background: 'green', width: '20px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            P
        </nav>,
        waiting: <nav style={{ background: 'orange', width: '20px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            B
        </nav>

    }


    const translatePaymentMethod = {
        credit_card: <nav style={{ background: 'purple', width: '50px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Cartão
        </nav>,
        boleto: <nav style={{ background: 'grey', width: '50px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Boleto
        </nav>,
        pix: <nav style={{ background: 'blue', width: '50px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Pix
        </nav>,
        courtesy: <nav style={{ background: 'brown', width: '50px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Cortesia
        </nav>,
        cortesy: <nav style={{ background: 'brown', width: '50px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Cortesia
        </nav>,
        cortesia: <nav style={{ background: 'brown', width: '50px', height: '20px', borderRadius: '5px', color: '#fff', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Cortesia
        </nav>,
    }


    return <>


        <table className="tableRef" style={{ width: '100%', maxWidth: '100%', margin: '0px' }}>

            {sortedList?.map(item => {
                return <tr key={`resumo-vendas-${item?._id?.toString()}`}>
                    <td>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div> <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#777' }}>{item?.dateWithHour}</p></div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                                {translatePayment?.[item?.payment_status_id]}
                                {translatePaymentMethod?.[item?.payment_method_id]}
                            </div>
                        </div>
                        <div>


                            <div >
                                <p style={{ fontSize: '12px', fontWeight: 'bold' }}> {item?.comprador} {item?.titular ? <span style={{ color: '#A71C20' }}>{`> ${item?.titular}`}</span> : ''}</p>
                            </div>
                            <div>
                                <p style={{ fontSize: '12px' }}> {item?.product} </p>
                            </div>
                        </div>
                    </td>

                </tr>
            })}

        </table>


    </>


}

export default ListLastTickets;
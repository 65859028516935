import React, { useEffect, useState } from 'react';
import { FaCheck, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import CheckboxInput from 'src/components/Forms/CheckboxInput';
import { authTitle } from 'src/config';
import { useConfiguration } from 'src/hooks/Configuration';
import Input from '../components/Forms/Input';
import MaskedInput from '../components/Forms/MaskedInput';

import SelectSimple from '../components/Forms/SelectSimple';
import { useLanguage } from "../hooks/Language"
import { countries } from "../pages/Products/modules/Login/countries";
import api from '../services/api';
import states from './SignUp/FormContainer/states';
interface IOption {
    value: string;
    label: string;
}
interface IProps {
    changeCountry: Function;
    countryISO: string;
    checkUserValue?: Function;
}



export const checkDocumentNumber = async (document_number) => {

    const doc = document_number?.toString().replace(/[^0-9]/g, '');
    const valid = await api.get('check-user-document_number', { params: { document_number: doc } });

    return valid?.data?.valid || 'no';

}


const DefaultSignUpFormV4: React.FC<IProps> = ({ checkUserValue = (value) => { }, changeCountry }) => {

    const [countryISO, setCountryISO] = useState('BR')
    const [checkUser, setCheckUser] = useState('')
    const [currentDocumentNumber, setCurrentDocumentNumber] = useState('');
    const { translate } = useLanguage();

    const { configuration } = useConfiguration();

    const readFields = () => {

        const elements: Array<JSX.Element> = [];

        configuration?.current_event_id_response?.sign_up_fields?.map((field: Record<string, any>) => {

            if (field?.fields === 'birth_date') {
                elements.push(<MaskedInput
                    mask="99/99/9999"
                    name="birth_date"
                    placeholder="Data de nascimento (DD/MM/AAAA)"
                />)
            }
            else if (field?.fields === 'state') {

                elements.push(countryISO === 'BR' ? <SelectSimple options={states} name="state" label="Estado" /> :

                    <Input name="state" placeholder={'Província / Região da Fundação'} />)

            }
            else if (field?.fields === 'phone') {

                elements.push(countryISO === 'BR' ? <MaskedInput
                    mask="(99) 999999999"
                    name="phone"
                    placeholder="Telefone com DDD"
                /> : <Input name="phone" placeholder={'Telefone com DDD'} />)

            }
            else if (field?.type === 'select') {

                const options: Array<IOption> = [];
                field?.options?.map((item) => {
                    options.push({ value: item, label: item })
                }, [])

                elements.push(<SelectSimple options={options} name={field?.fields} label={field?.title} />)

            }
            else {
                elements.push(<Input name={field?.fields} placeholder={field?.title} />)

            }






        })
        return elements;
    }

    return <>



        <SelectSimple index={0} setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

        {countryISO === 'BR' ?
            <SelectSimple index={1} options={states} name="state" label={'Estado'} />
            : <Input name="state" placeholder="Estado" />}
        <Input name="city" placeholder="Município" />


        {countryISO === 'BR' ? <MaskedInput mandatory={true}
            mask="999.999.999-99"
            name="document_number"
            setCallback={(value) => setCurrentDocumentNumber(value)}
            placeholder={translate('CPF')}
        /> : <Input setCallback={(value) => setCurrentDocumentNumber(value)} mandatory={true} name="document_number"
            placeholder={translate('Nº do documento')} />}

        <Input name="name" placeholder="Nome completo" />
        <Input name="email" placeholder="E-mail" />


        <MaskedInput
            mask="99/99/9999"
            name="extra_1"
            placeholder={translate('Data de Nascimento (DD/MM/YYYY)')}
        />

        {countryISO === 'BR' ?
            <MaskedInput name="phone" mask='(99) 99999.9999' placeholder={'Telefone com DDD'} />
            : <Input name="phone" placeholder="Telefone" />}

        <SelectSimple index={3} options={[
            { label: 'Acadêmico(a) de medicina', value: 'Acadêmico de medicina' },
            { label: 'Acadêmico(a) de outras áreas da saúde', value: 'Acadêmico de outras áreas da saúde' },
            { label: 'Aluno(a) FAMETRO', value: 'Aluno FAMETRO' },
            { label: 'Demais profissoinais de Saúde', value: 'Demais profissoinais de Saúde' },
            { label: 'Médico', value: 'Médico' },
            { label: 'Médico(a) Residente', value: 'Médico Residente' },

        ]} name="category_id" label={'Categoria'} />



        <SelectSimple index={5}

            options={[
                { label: 'WhatsApp', value: 'WhatsApp' },
                { label: 'Indicação', value: 'Indicação' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Linkedin', value: 'Linkedin' },
                { label: 'E-mail marketing', value: 'E-mail marketing' },
                { label: 'SMS', value: 'SMS' },
                { label: 'Workplace', value: 'Workplace' },
                { label: 'Google', value: 'Google' },
                { label: 'Outros', value: 'Outros' },



            ]}
            name="know_about"

            label="Como ficou sabendo do Bariatric Channel?"
        />


        {readFields()}


        <Input
            icon={FiLock}
            name="password"
            type="password"
            placeholder={translate('Cadastre sua senha de acesso')}
        />






    </>
}

export default DefaultSignUpFormV4;
export const hash = 'd1180339-117d-11ec-9ec1-1684972fa0df';

// export const gateKey = '';
export const gateKey = 'ek_live_g5Df6bp6y5ptAKeOVOpBloZHmqY0sb';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-global-v3.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br';



//export const apiSocket = 'http://localhost:8080';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const websitePath = 'https://bariatricchannel.com';
export const urlWebsite = 'https://bariatricchannel.com';
export const supportNumber = `5551981476007`;
export const home = 'Home';
export const haveTranslate = true;
export const defaultProjectHash = 'portal-bariatric-channel';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Bariatric Channel';
export const logo = '/assets/logo2.png';
export const background = '/assets/Fundo.jpg';
export const authTitle = 'Bariatric Channel';
export const dadosOrganizacao = '';
export const social = {
    whatsapp: '',
    instagram: 'bariatricchannel',
    facebook: 'BariatricChannel',
    linkedin: 'company/bariatric-channel/',
    youtube: '',
    spotify: '',
    twitter: 'BariatricChann',
};


export const defaultTheme = {
    defaultBackground: '#fff',
    defaultColor: '#333',
    logo: {
        minWidth: '450px',
    },
    menu: {
        background: '#fff',
        backgroundSticky: '#fff',
        backgroundMobile: '#333',
        color: '#333',
        shadow: '0',
        logoColor: 'none',
    },
};

export const getToken = (): string | null => {
    const token = localStorage.getItem(`@${authTitle}:token`);
    return token;
};

import React, { useCallback, useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { dateLanguage, dateSimple, simpleDateBR, simpleDateUS } from '../../../../utils/date';
import { addHours, differenceInCalendarDays } from 'date-fns';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import downloadit, { downloaditPost } from '../../../../utils/download';
import { apiUrl } from '../../../../config';
import { useToast } from '../../../../hooks/Toast';
import { date } from 'yup';
import Loader from '../../core/components/Loader';
import { FaChartBar, FaChartPie, FaChevronCircleDown, FaChevronCircleUp, FaFileCsv, FaFileExcel, FaInfo, FaRocket, FaSpinner } from 'react-icons/fa';
import ChartBar from '../../core/components/Charts/Bar';
import PieChart from '../../core/components/Charts/Pie';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLoading } from '../../../../hooks/LoadingHook';
import SalesByDay from './modules/SalesByDay';
import SalesProductsByDay from './modules/SalesProductsByDay';
import Compradores from './modules/Compradores';
import VendasRealizadas from './modules/Vendas';
import checkDifferenceDays from './functions/checkDifferenceDays';
import checkTicketsByDay from './functions/checkTicketsByDay';
import TicketsChart from './modules/TicketsChart';
import ChartItem from './modules/ChatItem';
import ListCourtesies from './modules/ListCourtesies';
import ListLastTickets from './modules/ListLastTickets';
import ListProducts from './modules/ListProducts';
import ListTicketsByDay from './modules/ListTicketsByDay';
import ListSalesByDay from './modules/ListSalesByDay';
import sleep from '../../../../utils/sleep';


interface IGroup {
  search: Array<Record<string, any>>;
  data: Array<Record<string, any>>;
  where?: Record<string, any>;
  prefix?: string;
}


const getGroup = ({ data, search, where = {}, prefix = '' }: IGroup) => {

  const keys = Object.keys(where);


  let newData = data;
  /* Make a filter on data */
  keys.map(key => {

    newData = newData.filter(d => d[key] === where[key]);
  })

  const report: Array<IResume> = [];

  /* Searchable without ignored fields */
  search.map(s => {

    report.push({
      title: `${prefix}${s.label}`,
      column: s.ref, /* column name */
      data: []
    })


  })

  newData.map(filterDataLine => {


    report.map((item, index) => {

      const columnName = filterDataLine[item.column] ? filterDataLine[item.column].trim().toUpperCase() : 'Não informado';

      const indexSubItem = report[index].data.findIndex(elem => elem?.title === columnName);



      if (indexSubItem >= 0) {
        report[index].data[indexSubItem].count = report[index].data[indexSubItem].count + 1;
        report[index].data[indexSubItem].value = report[index].data[indexSubItem]?.value ? (report[index]?.data[indexSubItem]?.value || 0) + 1 : 1;
      }
      else {
        report[index].data.push({ column: item?.column, id: columnName, title: columnName, label: columnName, value: 1, count: 1 })
      }



    });


  })





  return report;

}


interface IDetails {
  title: string;
  column?: string;
  id?: string;
  count: number;
  label?: string;
  value?: number;
}


interface IResume {
  data: Array<IDetails>;
  title: string;
  column: string;
  setCallback?: Function;
  active?: boolean;
}

interface IResumeList {
  data: Array<IResume>
}

const ReadResumeList: React.FC<IResumeList> = ({ data }) => {



  const [currentResume, setCurrentResume] = useState('');



  return <>
    {data?.length > 0 ? data.map(resume => <ReadResume column={resume.column} title={resume.title} data={resume.data} active={resume.title === currentResume ? true : false} setCallback={(value) => setCurrentResume(value)} />) : <></>}
  </>

}

const ReadResume: React.FC<IResume> = ({ title = '', data, active = false, setCallback = (value) => { } }) => {

  const [type, setType] = useState('details');

  const convertToArrayAndExport = ({ data, type = 'xlsx', title }) => {
    data.sort((a, b) => a?.count < b?.count ? 1 : a?.count > b?.count ? -1 : 0);
    const content = [[title, 'Quantidade']];

    data.map(item => {
      content.push([item.title, item.count || 0])
    })



    return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

  }

  return <>

    <div className='list-template-dark'>
      <div className='list-template-date'>{title}</div>
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <div className='list-template-quantity' style={{ width: '70px' }}>{data.length || '0'}</div>


        <div className='list-template-quantity' style={{ cursor: 'pointer' }}>
          <FaInfo onClick={() => { setCallback(title); setType('details') }} size={20} />
          <FaFileExcel size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'xlsx' })} />
          <FaFileCsv size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'csv' })} />
          {/*<FaChartBar onClick={() =>   { setType('chartBar'); setCallback(title)}} size={20}/> */}
          <FaChartPie onClick={() => { setType('chartPie'); setCallback(title) }} size={20} />{active ? <FaChevronCircleUp onClick={() => setCallback(title)} size={20} /> : <FaChevronCircleDown onClick={() => setCallback(title)} size={20} />}</div>
      </div>

    </div>
    <div style={{ display: active ? 'flex' : 'none', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ReadDetails type={type} data={data} />

    </div>

  </>



}



interface IPropsReadDetails {
  data: Array<IDetails>;
  type: string;
}


const ReadDetails: React.FC<IPropsReadDetails> = ({ data, type = 'details' }) => {

  /* type can be "Details" or "Chart" */


  const [contentList, setContentList] = useState<Array<IDetails>>([]);
  const [content, setContent] = useState<Array<JSX.Element>>([]);

  const getContent = {
    details: (dataDetails: Array<IDetails>) => {
      return <table style={{ width: '100%' }}>
        {dataDetails.map((d, index) => {

          return <tr key={`${d.title}-${d.count}-${index}`} className='list-template'>
            <td className='list-template-date'>{d.title}</td>
            <td className='list-template-quantity'>{d.count || '0'}</td>
          </tr>

        })}
      </table>;




    },
    chartBar: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', minWidth: `${dataDetails.length * 100}px`, height: '50vh' }}><ChartBar indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    },
    chartPie: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', height: '50vh', padding: '20px 0px', overflowY: 'auto' }}><PieChart indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    }

  }


  useEffect(() => {

    const newList = [...data];

    newList.sort((a, b) => {
      if (a?.count < b?.count) { return 1; }
      else if (a?.count > b?.count) { return -1; }
      else { return 0; }

    })

    setContentList(newList);

  }, [data]);




  return getContent?.[type] ? getContent?.[type](contentList) : <> - </>;



}



interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IProps {
  hash?: string;
}

const PainelFinanceiro: React.FC<IProps> = ({ hash = '' }) => {

  const { configuration } = useConfiguration();
  const { addLoading, removeLoading } = useLoading();
  const [page, setPage] = useState('ticket');
  const [activeList, setActiveList] = useState('tickets');
  const [activeBarChart, setActiveBarChart] = useState('tickets');
  const [data, setData] = useState<Record<string, any>>({
    users: [],
    products: [],
    sales: [],
    salesProducts: []
  });


  const translatePaymentMethod = {
    credit_card: 'Cartão de crédito',
    boleto: 'Boleto',
    pix: 'Pix',
    courtesy: 'Cortesia',
    cortesy: 'Cortesia',
    cortesia: 'Cortesia',
  }



  const loadDataRefresh = async (dataItems) => {



    const usersPromise = api.get(`/students`, { params: { limitItems: 10000, where: { updatedAt: { "$gt": addHours(new Date(), -6) } } } });
    const productsPromise = api.get(`/products`, { params: { where: { project_id: configuration?.url, updatedAt: { "$gt": addHours(new Date(), -6) } }, limitItems: 1000 } });
    const salePromise = api.get(`/sales`, { params: { where: { "$and": [{ project_id: configuration?.url }, { updatedAt: { "$gt": addHours(new Date(), -6) } }, { "$or": [{ payment_status_id: 'paid' }, { payment_status_id: 'waiting' }] }] }, limitItems: 10000 } });
    const saleProductPromise = api.get(`/sales-products`, { params: { where: { "$and": [{ project_id: configuration?.url }, { updatedAt: { "$gt": addHours(new Date(), -6) } }, { "$or": [{ payment_status_id: 'paid' }, { payment_status_id: 'waiting' }] }] }, limitItems: 10000 } });






    const [users, products, sales, salesProducts] = await Promise.all([usersPromise, productsPromise, salePromise, saleProductPromise]);


    salesProducts?.data?.rows.map((item, index) => {

      salesProducts.data.rows[index].brDate = simpleDateBR(item?.createdAt)
      salesProducts.data.rows[index].payment_type = item?.payment_method_id === 'courtesy' || item?.payment_method_id === 'cortesia' || item?.payment_method_id === 'cortesy' ? 'Cortesia' : 'Venda';

      salesProducts.data.rows[index].payment_method = translatePaymentMethod?.[item?.payment_method_id];
      salesProducts.data.rows[index].product = item?.product_id_response?.title;

    })

    sales?.data?.rows.map((item, index) => {

      sales.data.rows[index].brDate = simpleDateBR(item?.createdAt);
      sales.data.rows[index].payment_type = item?.payment_method_id === 'courtesy' || item?.payment_method_id === 'cortesia' || item?.payment_method_id === 'cortesy' ? 'Cortesia' : 'Venda';
      sales.data.rows[index].payment_method = translatePaymentMethod?.[item?.payment_method_id];
      sales.data.rows[index].payer_type_reference = item?.cortesy_hash_id ? 'Cortesia' : item?.payer_type === 'cpf' ? 'Pessoa Física' : item?.payer_type === 'cnpj' ? 'Pessoa Jurídica' : 'Não identificado';


    })


    const items = { ...dataItems };

    sales.data.rows?.map(i => {
      const index = items?.sales?.findIndex(sale => sale?._id?.toString() === i?._id?.toString());

      if (index < 0) {
        items?.sales?.push(i);
      }
      else {

        if (items?.sales[index]?.payment_status_id !== i?.payment_status_id) {
          items.sales[index] = { ...i };
        }


      }

    })

    salesProducts.data.rows?.map(i => {
      const index = items?.salesProducts?.findIndex(sale => sale?._id?.toString() === i?._id?.toString());

      if (index < 0) {
        items?.salesProducts?.push(i);
      }
      else {

        if (items?.salesProducts[index]?.payment_status_id !== i?.payment_status_id) {
          items.salesProducts[index] = { ...i };
        }


      }
    })

    users.data.rows?.map(i => {
      const index = items?.users?.findIndex(student => student?._id?.toString() === i?._id?.toString());

      if (index < 0) {
        items?.users?.push(i);
      }

    })

    products.data.rows?.map(i => {
      const index = items?.products?.findIndex(item => item?._id?.toString() === i?._id?.toString());

      if (index < 0) {
        items?.products?.push(i);
      }

    })

    setData({ ...items })

    await sleep(30000);
    loadDataRefresh(items);

  }

  const loadData = async () => {

    addLoading({ title: 'Preparando relatórios', key: 'loading' });

    const usersPromise = api.get(`/students`, { params: { limitItems: 10000 } });
    const productsPromise = api.get(`/products`, { params: { where: { project_id: configuration?.url }, limitItems: 1000 } });
    const salePromise = api.get(`/sales`, { params: { where: { "$and": [{ project_id: configuration?.url }, { "$or": [{ payment_status_id: 'paid' }, { payment_status_id: 'waiting' }] }] }, limitItems: 10000 } });
    const saleProductPromise = api.get(`/sales-products`, { params: { where: { "$and": [{ project_id: configuration?.url }, { "$or": [{ payment_status_id: 'paid' }, { payment_status_id: 'waiting' }] }] }, limitItems: 10000 } });






    const [users, products, sales, salesProducts] = await Promise.all([usersPromise, productsPromise, salePromise, saleProductPromise]);


    salesProducts?.data?.rows.map((item, index) => {

      salesProducts.data.rows[index].brDate = simpleDateBR(item?.createdAt)
      salesProducts.data.rows[index].payment_type = item?.payment_method_id === 'courtesy' || item?.payment_method_id === 'cortesia' || item?.payment_method_id === 'cortesy' ? 'Cortesia' : 'Venda';

      salesProducts.data.rows[index].payment_method = translatePaymentMethod?.[item?.payment_method_id];
      salesProducts.data.rows[index].product = item?.product_id_response?.title;

    })

    sales?.data?.rows.map((item, index) => {

      sales.data.rows[index].brDate = simpleDateBR(item?.createdAt);
      sales.data.rows[index].payment_type = item?.payment_method_id === 'courtesy' || item?.payment_method_id === 'cortesia' || item?.payment_method_id === 'cortesy' ? 'Cortesia' : 'Venda';
      sales.data.rows[index].payment_method = translatePaymentMethod?.[item?.payment_method_id];
      sales.data.rows[index].payer_type_reference = item?.cortesy_hash_id ? 'Cortesia' : item?.payer_type === 'cpf' ? 'Pessoa Física' : item?.payer_type === 'cnpj' ? 'Pessoa Jurídica' : 'Não identificado';


    })


    setData({
      users: users?.data?.rows,
      products: products?.data?.rows,
      sales: sales?.data?.rows,
      salesProducts: salesProducts?.data?.rows
    })

    removeLoading('loading');
    await sleep(30000);
    loadDataRefresh({
      users: users?.data?.rows,
      products: products?.data?.rows,
      sales: sales?.data?.rows,
      salesProducts: salesProducts?.data?.rows
    })

  }








  useEffect(() => {
    loadData();

  }, [])







  const paidSales = data?.sales?.filter(ticket => ticket?.payment_status_id === 'paid')

  const paidTickets = data?.salesProducts?.filter(ticket => ticket?.payment_status_id === 'paid')
  const waitingTickets = data?.salesProducts?.filter(ticket => ticket?.payment_status_id === 'waiting')

  const differenceInDays = checkDifferenceDays(configuration?.current_event_id_response?.sale_start_date, configuration?.current_event_id_response?.start_at);

  const daysToEnd = checkDifferenceDays(new Date(), configuration?.current_event_id_response?.start_at);
  const daysFromStart = checkDifferenceDays(configuration?.current_event_id_response?.sale_start_date, new Date());


  const ticketsByDay = checkTicketsByDay(paidTickets?.length, daysFromStart)?.toFixed(2)

  const productsQuantity = data?.products ? data?.products?.reduce((prev, item) => {

    prev = prev + parseInt(item?.total_quantities || '0', 10);

    return prev;


  }, 0) : 0;

  const totalWaitingPercentage = (waitingTickets?.length / productsQuantity) * 100;
  const totalPercentage = (paidTickets?.length / productsQuantity) * 100;

  return <Container>


    <table className='tableRef full-width' style={{ maxWidth: '100%' }}>
      <tbody>
        <tr>
          <td> <h2>Início das Vendas: {simpleDateBR(configuration?.current_event_id_response?.sale_start_date)}</h2></td>
          <td><h2>Data do evento: {simpleDateBR(configuration?.current_event_id_response?.start_at)}</h2></td>
          <td><h2>Vendas confirmadas: {paidSales?.length}</h2></td>
          <td><h2>Média de ingressos por dia: {ticketsByDay}</h2></td>
        </tr>
      </tbody>

    </table>


    <div className='full-width' style={{ background: '#fff' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <h2>Ingressos Vendidos: {paidTickets?.length} ({totalPercentage?.toFixed(2)}%) / Ingressos Bloqueados: {waitingTickets?.length} ({totalWaitingPercentage?.toFixed(2)}%)</h2>
        <h2>Ingressos cadastrados: {productsQuantity}</h2>
      </div>
      <div className='full-width row row-start' style={{ background: '#ddd', position: 'relative' }}>
        <p className='ball positionLeft'>{paidTickets?.length}</p>
        <div style={{ minWidth: `${totalPercentage}%`, width: `${totalPercentage}%`, background: 'orange', height: '50px', position: 'relative' }}>


          <FaRocket className='rocket' />

        </div>
        <div style={{ minWidth: `${totalWaitingPercentage}%`, width: `${totalWaitingPercentage}%`, background: 'grey', height: '50px', position: 'relative' }} />
        <p className='ball positionRight'>{productsQuantity - paidTickets?.length}</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px' }}>
        <div>
          <p>{daysToEnd < 0 ? 'Evento encerrado' : `Faltam ${daysToEnd} dias`}</p>

        </div>
      </div>
    </div>
    <div className='full-width row' style={{ display: 'flex', alignItems: 'flex-start', gap: '15px' }}>
      <div className='row gap-sm' style={{ width: '100%', flexWrap: 'wrap' }}>
        <div className='full-width  bandooBox  ' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '15px' }}>
          <div className='full-width row gap-sm'>
            <button onClick={() => setActiveBarChart('tickets')} className={`greenButton2Mini ${activeBarChart === 'tickets' ? 'active' : ''}`}> Volume de ingressos</button>

            <button onClick={() => setActiveBarChart('sales')} className={`greenButton2Mini ${activeBarChart === 'sales' ? 'active' : ''}`}> Volume de vendas</button>
          </div>

          <h2>{activeBarChart === 'tickets' ? 'Volume de ingressos por dia' : 'Volume de vendas por dia'}</h2>

          <ChartItem renderData={activeBarChart === 'tickets' ? data?.salesProducts?.sort((a, b) => a?.brDate > b?.brDate ? 1 : a?.brDate < b?.brDate ? -1 : 0) : data?.sales?.sort((a, b) => a?.brDate > b?.brDate ? 1 : a?.brDate < b?.brDate ? -1 : 0)} keyName={'brDate'} type="bar" />
        </div>
        <div className='full-width row' style={{ display: 'flex', alignItems: 'flex-start', gap: '15px' }}>
          <div className='max-400 bandooBox' style={{ width: '100%', maxHeight: '400px' }}>
            <h2>{activeBarChart === 'tickets' ? 'Ingressos por forma de pagamento' : 'Vendas por forma de pagamento'}</h2>

            <ChartItem renderData={activeBarChart === 'tickets' ? data?.salesProducts : data?.sales} keyName={'payment_method'} type="pie" />
          </div>
          {activeBarChart === 'tickets' ? <div className='max-400 bandooBox' style={{ width: '100%', maxHeight: '400px' }}>
            <h2>{activeBarChart === 'tickets' ? 'Tipos de ingressos comercializados' : 'Vendas por tipo de oferta'}</h2>
            <ChartItem renderData={activeBarChart === 'tickets' ? data?.salesProducts : data?.sales} keyName={'product'} type="pie" />
          </div> : <div className='max-400 bandooBox' style={{ width: '100%', maxHeight: '400px' }}>

            <h2>{'Tipo de comprador'}</h2>
            <ChartItem renderData={data?.sales} keyName={'payer_type_reference'} type="pie" />


          </div>}

        </div>
        <div className='full-width row' style={{ display: 'flex', alignItems: 'flex-start', gap: '15px' }}>

          <div className='max-400 bandooBox' style={{ width: '100%', maxHeight: '400px' }}>
            <h2>{activeBarChart === 'tickets' ? 'Ingressos por tipo de oferta' : 'Vendas por tipo de oferta'}</h2>
            <ChartItem renderData={activeBarChart === 'tickets' ? data?.salesProducts : data?.sales} keyName={'payment_type'} type="pie" />
          </div>

        </div>





      </div>
      <div className=' bandooBox' style={{ overflowY: 'auto', minWidth: '34%', maxHeight: '80vh', minHeight: '80vh' }}>
        <div className='full-width row gap-sm'>
          <button onClick={() => setActiveList('tickets')} className={`greenButton2Mini ${activeList === 'tickets' ? 'active' : ''}`}> Últimos ingressos</button>

          <button onClick={() => setActiveList('courtesies')} className={`greenButton2Mini ${activeList === 'courtesies' ? 'active' : ''}`}> Cortesias</button>
          <button onClick={() => setActiveList('products')} className={`greenButton2Mini ${activeList === 'products' ? 'active' : ''}`}> Produtos</button>

          <button onClick={() => setActiveList('ticketsByDay')} className={`greenButton2Mini ${activeList === 'ticketsByDay' ? 'active' : ''}`}>QTD</button>
          <button onClick={() => setActiveList('salesByDay')} className={`greenButton2Mini ${activeList === 'salesByDay' ? 'active' : ''}`}>R$ </button>

        </div>


        {activeList === 'tickets' ? <ListLastTickets salesProducts={data.salesProducts} sales={paidSales} /> : <></>}
        {activeList === 'courtesies' ? <ListCourtesies salesProducts={paidTickets} sales={paidSales} /> : <></>}
        {activeList === 'products' ? <ListProducts products={data?.products} salesProducts={paidTickets} sales={paidSales} /> : <></>}

        {activeList === 'ticketsByDay' ? <ListTicketsByDay products={data?.products} salesProducts={paidTickets?.sort((a, b) => a?.approved_at < b?.approved_at ? 1 : a?.approved_at > b?.approved_at ? -1 : 0)} sales={paidSales} /> : <></>}
        {activeList === 'salesByDay' ? <ListSalesByDay products={data?.products} salesProducts={paidTickets} sales={paidSales?.sort((a, b) => a?.approved_at < b?.approved_at ? 1 : a?.approved_at > b?.approved_at ? -1 : 0)} /> : <></>}



      </div>


    </div>








  </Container>

};

export default PainelFinanceiro;

import { differenceInCalendarDays } from "date-fns";

const checkDifferenceDays = (start, end) => {

    if (!start || !end) {
        return '-'
    }

    const startDate = new Date(start);
    const endDate = new Date(end);


    const difference = differenceInCalendarDays(endDate, startDate);
    return difference;
}

export default checkDifferenceDays;
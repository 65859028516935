import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { hash, urlWebsite } from '../../config';
import { useConfiguration } from '../../hooks/Configuration';
import { useLanguage } from '../../hooks/Language';
import api from '../../services/api';
import themeDefault from '../../styles/ThemeComponents';
import { loadFromJson } from '../../utils/loadFromJson';
import { Container, Sponsor, Apoio, SponsorGrid, ApoioGrid } from './styles';


interface ISponsor {
  url: string;
  link_url?: string;

}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
interface ISponsors {
  title: string;
  position: number;
  sponsors: Array<ISponsor>;
  size?: string;
}

interface IProps {
  project_id?: string;
}

const FooterLayout: React.FC<IProps> = ({ children, project_id }) => {
  const { configuration } = useConfiguration();
  const [sponsors, setSponsors] = useState<Array<ISponsors>>([])
  const { projectHash = configuration?.url, eventHash } = useParams<IParamsURL>();
  const { language } = useLanguage();

  useEffect(() => {

    const load = async () => {

      let data: Array<ISponsors> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: project_id || projectHash, table: 'Sponsor' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(eventHash ? `sponsors-list/${project_id || projectHash}/${eventHash}` : `sponsors-list/${project_id || projectHash}`);

        data = response.data.rows;
      }



      if (data) {

        data.sort((a, b) => a.position > b.position ? 1 : a.position < b.position ? -1 : 0);

        setSponsors(data);
      }
      return;
    }

    load();

  }, [])

  return (
    <>


      {sponsors && sponsors.length > 0 && <Container id="patrocinadores" style={{ margin: '20px auto' }}>
        { /* <h2 style={{ color: 'rgb(0,14,64)', fontSize: '32px', marginTop: '10px', marginBottom: '25px' }} >Patrocinadores</h2> */}

        {sponsors && sponsors.map(lists => (


          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', minWidth: '350px', maxWidth: '1400px', margin: '10px auto' }}>
            <p style={{ color: '#333', fontSize: '18px', marginTop: '10px', marginBottom: '0px' }}>{lists?.[`title_${language}`] ? lists[`title_${language}`] : lists.title}</p>
            <ApoioGrid size={lists.size}>
              {lists.sponsors && lists.sponsors.map(apoio => (apoio.link_url ? <a href={apoio.link_url} target="_BLANK" style={{ background: '#fff' }}><Apoio background={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></a> : <div style={{ background: '#fff' }} ><Apoio background={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></div>))}

            </ApoioGrid>
          </div>

        ))


        }

      </Container>}

    </>
  );
};

export default FooterLayout;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlWebsite } from 'src/config';
import { useLanguage } from 'src/hooks/Language';

import { Container, Sponsor, Apoio, SponsorGrid, ApoioGrid, ApoioImg } from './styles';


interface ISponsor {
  url: string;
  link_url?: string;
  computer?: string;
  mobile?: string;

}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
interface ISponsors {
  title: string;
  title_font_color: string;
  title_font_size: string;
  position: number;
  sponsors: Array<ISponsor>;
  size?: string;
  status?: string;
}

interface IProps {
  data: Record<string, any>[];
}

const SponsorsWidgets: React.FC<IProps> = ({ data }) => {
  const { language } = useLanguage();
  return (
    <>


      {data?.length > 0 && <Container style={{ background: 'none' }} >
        { /* <h2 style={{ color: 'rgb(0,14,64)', fontSize: '32px', marginTop: '10px', marginBottom: '25px' }} >Patrocinadores</h2> */}






        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', minWidth: '350px', maxWidth: '1200px', margin: '10px auto' }}>
          <ApoioGrid size={'p'}>
            {data.map(apoio => (apoio.link_url ? <a className='column' href={apoio.link_url} target="_BLANK"  >
              <ApoioImg computer={apoio?.computer} mobile={apoio?.mobile} src={`${urlWebsite}/${apoio.image}`} key={apoio.image} />
              {apoio?.title ? <h2 style={{ fontSize: '12px', lineHeight: '16px' }}>{apoio?.[`title_${language}`] ? apoio?.[`title_${language}`] : apoio?.[`title`]}</h2> : <></>}
              {apoio?.description ? <p style={{ fontSize: '10px', lineHeight: '14px' }} dangerouslySetInnerHTML={{ __html: apoio?.[`description_${language}`] ? apoio?.[`description_${language}`] : apoio?.[`description`] }} /> : <></>}
            </a> : <div className='column'   ><ApoioImg computer={apoio?.computer} mobile={apoio?.mobile} src={`${urlWebsite}/${apoio.image}`} key={apoio.image} />
              {apoio?.title ? <h2 style={{ fontSize: '12px', lineHeight: '16px' }}>{apoio?.[`title_${language}`] ? apoio?.[`title_${language}`] : apoio?.[`title`]}</h2> : <></>}
              {apoio?.description ? <p style={{ fontSize: '10px', lineHeight: '14px' }} dangerouslySetInnerHTML={{ __html: apoio?.[`description_${language}`] ? apoio?.[`description_${language}`] : apoio?.[`description`] }} /> : <></>}

            </div>))}

          </ApoioGrid>
        </div>





      </Container >}

    </>
  );
};

export default SponsorsWidgets;

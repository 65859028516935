import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { useLanguage } from '../../../../../../hooks/Language';
import CreditCardForm from './modules/CreditCard';
import GeneralForm from './modules/GeneralForm';
import CurrencyInput from '../../../../../../components/Forms/MaskedCurrencyInput';
import CompleteSaleFields from '../../../CompleteSaleFields';
import { FiArrowRight } from 'react-icons/fi';
import calculateDiscount from '../../Functions/calculateDiscount';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../components/Forms/SelectSimple';
import { countries } from '../../../Login/countries';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaKey } from '../../../../../../config';
import states from 'src/pages/SignUp/FormContainer/states';
interface IProps {
    paymentMethod: 'credit_card' | 'boleto' | 'pix' | 'cortesy';

    cart: Array<Record<string, any>>;
    couponInfo?: Record<string, any>;
    changeInstallment: Function;
    installmentValue: number;
    quantity: number;
    sendPaymentRequest: Function;
    applyCoupon: Function;
    courtesyCode: string;
}

const PaymentMethodForm: React.FC<IProps> = ({ courtesyCode, paymentMethod, couponInfo, cart, changeInstallment, installmentValue, quantity, sendPaymentRequest, applyCoupon }) => {

    const { translate } = useLanguage();
    const product = cart?.[0] || {};
    const recaptchaRef = useRef(null);
    const [type, setType] = useState('cpf');
    const [countryISO, setCountryISO] = useState('BR');
    const paymentMethodElements = {

        boleto: () => <GeneralForm />,
        pix: () => <GeneralForm />,
        cortesy: () => <></>,
        courtesy: () => <></>,
    }

    const getCaptchaValue = (elem: any): string => {
        return elem?.getValue ? elem?.getValue() : '';
    };

    return <>

        <Form className="form"

            initialData={{ country: "BR", installments: installmentValue, quantity: quantity, document_type: type }}
            onSubmit={(data) => { data.recaptcha = getCaptchaValue(recaptchaRef?.current); sendPaymentRequest({ data, paymentMethod: calculateDiscount(couponInfo, cart).discountedValue > 0 ? paymentMethod : 'boleto', cart, coupon: couponInfo, courtesy: courtesyCode }) }}

        >


            {product.fields && product.fields.length > 0 ? <>  <CompleteSaleFields applyCoupon={applyCoupon} fields={product.fields} />
                <br />
            </> : <></>}

            <div className='outerBlock'>
                <h2 style={{ margin: ' 0px', fontSize: '14px', marginBottom: '5px', width: '100%', color: '#333', borderBottom: '3px solid #A71C20', padding: '5px 0px', textAlign: 'left' }}>{translate('3. Dados pessoais')}</h2>
                <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

                <Input type='text' name='name' placeholder='Nome completo' />
                <Input type='text' name='email' placeholder='E-mail' />
                {countryISO === 'BR' ? <MaskedInput
                    mask="999.999.999-99"
                    name="document_number"
                    placeholder={translate('CPF')}
                /> : <Input name="document_number"
                    placeholder={translate('Nº do documento')} />}

                {countryISO === 'BR' ? <MaskedInput mask='(99) 99999.9999' type='text' name='phone' placeholder='Celular / Whatsapp' />
                    : <Input name="phone"
                        placeholder={translate('Número do telefone')} />}

                {countryISO === 'BR' ?
                    <SelectSimple options={states} name="state" label={'Estado'} />
                    : <Input name="state" placeholder="Estado" />}
                <Input name="city" placeholder="Município" />



                <SelectSimple options={[
                    { label: 'Acadêmico(a) de medicina', value: 'Acadêmico de medicina' },
                    { label: 'Acadêmico(a) de outras áreas da saúde', value: 'Acadêmico de outras áreas da saúde' },
                    { label: 'Aluno(a) FAMETRO', value: 'Aluno FAMETRO' },
                    { label: 'Demais profissoinais de Saúde', value: 'Demais profissoinais de Saúde' },
                    { label: 'Médico', value: 'Médico' },
                    { label: 'Médico(a) Residente', value: 'Médico Residente' },

                ]} name="category_id" label={'Categoria'} />



                <SelectSimple

                    options={[
                        { label: 'WhatsApp', value: 'WhatsApp' },
                        { label: 'Indicação', value: 'Indicação' },
                        { label: 'Instagram', value: 'Instagram' },
                        { label: 'Facebook', value: 'Facebook' },
                        { label: 'Linkedin', value: 'Linkedin' },
                        { label: 'E-mail marketing', value: 'E-mail marketing' },
                        { label: 'SMS', value: 'SMS' },
                        { label: 'Workplace', value: 'Workplace' },
                        { label: 'Google', value: 'Google' },
                        { label: 'Outros', value: 'Outros' },



                    ]}
                    name="know_about"

                    label="Como ficou sabendo do Bariatric Channel?"
                />


            </div>

            <div style={{ marginTop: '10px' }} />



            <div className='outerBlock'>

                <h2 style={{ margin: ' 0px', fontSize: '14px', marginBottom: '5px', width: '100%', color: '#333', borderBottom: '3px solid #A71C20', padding: '5px 0px', textAlign: 'left' }}>{translate('Emitir como Pessoa Física ou PJ?')}</h2>


                <SelectSimple index={10} style={{ zIndex: 100000 }}
                    setCallback={(value) => setType(value)}
                    options={[
                        { value: 'cpf', label: translate('Pessoa Física') },
                        {
                            value: 'cnpj',
                            label: translate('Entidade / Pessoa Jurídica'),
                        },
                    ]}
                    name="document_type"

                    label={translate('Emitir como')}
                />

                {type === 'cnpj' ? <>

                    <Input type='text' name='company' placeholder='Razão social' />
                    <MaskedInput mask='99.999.999/9999-99' type='text' name='company_document_number' placeholder='CNPJ' />



                </> : <></>}

            </div>

            <p style={{ fontSize: '12px', textAlign: 'center', width: '100%', margin: '10px 0px' }}>{translate('Ao me inscrever, afirmo que li e concordo com a política de privacidade e os termos de cancelamento e reembolso desta plataforma.')}</p>
            <aside style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', alignItems: 'center' }}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>

            <button className='greenButton2 buttonColored' type="submit">
                {translate('Efetuar pagamento')}

            </button>

        </Form>
    </>

};
export default PaymentMethodForm;

import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { FaChevronDown, FaChevronUp, FaPlus, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import DatePicker from '../DatePicker';
import { Form } from '@unform/web';

interface IListInput {
  label: string;
  name: string;
  value?: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list?: Array<IListInput>;
  placeholder: string;
  type?: string;
  label?: string;
}

const ArrayInputIncluder: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  list,
  label,
  type = 'text',
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);


  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);


  const [value, setNewValue] = useState(defaultValue || [type === 'text' ? '' : new Date()]);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
      setValue: (ref: any, valueN: string) => {
        setNewValue(valueN ? JSON.parse(valueN) : [type === 'text' ? '' : new Date()]);
      },
    });
  }, [fieldName, registerField]);

  const addInput = () => {
    const newHash = `q${new Date().getMilliseconds()}`;
    setNewValue([...value, '']);
  }

  const goUp = index => {
    const indexup = index - 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setNewValue([...newCurrent]);

    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setNewValue([...newCurrent]);
    }
  };



  const removeItem = (index) => {

    const item = [...value];
    item.splice(index, 1);

    setNewValue([...item]);

  }


  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label>{label}</label>
      <button type="button" onClick={addInput}>
        <FaPlus /> Adicionar
      </button>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />


      {value && value.map((v, index) => {

        return (
          <Container
            key={uuid()}
            {...rest}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocus}
          >
            <label>{label}</label>

            {type === 'text' ? <input
              placeholder={v.label}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={v}
              onChange={e => {
                const newValue = [...value];


                newValue[index] = e.target.value;



                setNewValue([...newValue]);
              }
              }
              name={uuid()}
            /> : <Form onSubmit={() => { }} initialData={{ [`date-${index}`]: value[index] }}>
              <DatePicker name={`date-${index}`} setCallback={(dateValue) => {

                const newValue = [...value];

                newValue[index] = dateValue;



                setNewValue([...newValue]);


              }} /></Form>}
            {error && (
              <Error title={error}>
                <FiAlertCircle />
              </Error>
            )}
            <div className='listModules'>
              <div><FaChevronUp onClick={() => goUp(index)} style={{ cursor: 'pointer' }} /></div>
              <div><FaChevronDown onClick={() => goDown(index)} style={{ cursor: 'pointer' }} /></div>
              <div><FaTrash onClick={() => removeItem(index)} style={{ cursor: 'pointer' }} /></div>
            </div>
          </Container>
        );
      })}

    </div>
  );
};

export default ArrayInputIncluder;

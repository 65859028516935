import React from 'react';
import { useConfiguration } from "../../../../hooks/Configuration";
import { useModal } from "../../../../hooks/Modal";
import EventsPages from "../../../../PagesApplication/HomeApplication/modules/Paginas";
import { scrollToID } from "../../../../utils/scrollTo";

import { FaChevronDown } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useLanguage } from 'src/hooks/Language';

interface IProps {
    content: Record<string, any>;
    closeMenu: Function;
    activeSubmenu: Function;
    active: boolean;
}

interface INavItem {
    title: string;
    callback: Function;
    active: boolean;
    haveSubmenu: boolean;
}


const RenderLink: React.FC<IProps> = ({ active, content, closeMenu, activeSubmenu }) => {

    const { language } = useLanguage();
    const { addModal } = useModal();
    const { title, url, type, submenu } = content;
    const haveSubmenu = submenu?.length > 0;
    const navigate = useHistory();
    const { configuration } = useConfiguration();


    console.log(content)

    const titleInfo = content?.[`title_${language}`] ? content?.[`title_${language}`] : content?.title;


    const goTo = (ID: string) => {
        navigate.push({
            pathname: `/`,
        });
        scrollToID(ID);
        closeMenu();
        document.getElementById(ID)?.focus();
    }


    const NavItem: React.FC<INavItem> = ({ title, haveSubmenu, active, callback }) => {
        return <nav style={{ color: '#000', cursor: 'pointer', border: '1px solid #ddd', justifyContent: 'space-between' }} tabIndex={0}

            onClick={() => callback(active)} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    return callback(active)
                }
            }}
            className='full-width row blackBackground pad-sm cursor midTitle4 scaleOnHover fadeIn' >
            <p> {title}</p> {haveSubmenu ? <FaChevronDown size={20} style={{ transform: `rotate(${active ? '-90' : '0'}deg)` }} /> : <></>}
        </nav>
    }
    const applyCloseMenu = () => {

        closeMenu()
    }

    const typeLink: Record<string, any> = {
        submenu: () => <NavItem title={titleInfo} active={active} haveSubmenu={haveSubmenu} callback={(active: boolean) => activeSubmenu()} />,
        page: () => <NavItem title={titleInfo} active={active} haveSubmenu={haveSubmenu} callback={(active: boolean) => goTo(url)} />,
        menu: () => <Link className="full-width" to={`/app/${configuration?.url}/paginas/${url}`}><NavItem title={titleInfo} active={active} haveSubmenu={haveSubmenu} callback={(active: boolean) => applyCloseMenu()} /></Link>,
        external: () => <a target={"_blank"} className="full-width" href={url}><NavItem title={titleInfo} active={active} haveSubmenu={haveSubmenu} callback={(active: boolean) => applyCloseMenu()} /></a>,
        modal: () => <NavItem title={titleInfo} active={active} haveSubmenu={haveSubmenu} callback={(active: boolean) => { addModal({ title: '', theme: 'whiteModalExtraPage', content: <EventsPages hash={url} project_id={configuration?.url} key="modal-menu" /> }) }} />,
        single_page: () => <Link className="full-width" to={`${url}`}><NavItem title={titleInfo} active={active} haveSubmenu={haveSubmenu} callback={(active: boolean) => applyCloseMenu()} /></Link>,
    }



    return haveSubmenu ? typeLink.submenu() : typeLink?.[type] ? typeLink?.[type]() : <></>

}

export default RenderLink;
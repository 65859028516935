import React from 'react';
import Row from '../Row';
import { ISection } from '../../hooks/PageCreatorContext';
import { FaPlus, FaArrowUp, FaArrowDown, FaPaintBrush } from 'react-icons/fa';
import { SectionStyle } from '../../styles';

interface ISectionProps {
    section: ISection;
    containerIndex: number;
    sectionIndex: number;
    context: Record<string, any>;
    editable: boolean;
    language: string;
}

const Section: React.FC<ISectionProps> = ({
    section,
    containerIndex,
    sectionIndex,
    editable = true,
    context = {},
    language,
}) => {
    const { addRow,
        addContent,
        moveSectionUp,
        moveSectionDown,
        moveRowUp,
        moveRowDown,
        setCurrentView,
        setSelectedIndices } = context;
    const style = section.style || {};



    return (
        <SectionStyle className='section' computer={style?.computer || {}} mobile={style?.mobile || {}}>

            {section.rows.map((row, rowIndex) => (
                <Row
                    key={rowIndex}
                    row={row}
                    containerIndex={containerIndex}
                    sectionIndex={sectionIndex}
                    rowIndex={rowIndex}
                    editable={editable}
                    context={context}
                    language={language}
                />
            ))}
        </SectionStyle>

    );
};

export default Section;
import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, Wrapper, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';
import { loadFromJson } from '../../utils/loadFromJson';
import { hash } from '../../config';
import { useLanguage } from '../../hooks/Language';


interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id: number;
  id: number;
  category_response?: Record<any, string>;
  social?: Record<any, string>;

}

interface ISpeakers {
  title: string;
  position: string;
  order_type: string;
  speakers: Array<ISpeakerData>;
}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
const Speakers: React.FC = () => {
  const [speakersData, setSpeakerData] = useState<Array<ISpeakers>>([]);
  const { projectHash, eventHash } = useParams<IParamsURL>();
  const { language } = useLanguage();
  const { configuration } = useConfiguration();




  const removerAcentos = (value) => {

    value = value.toUpperCase();
    value = value.replace('Í', 'I').replace('Ì', 'I').replace('Ĩ', 'I').replace('Ï', 'I').replace('Î', 'I');
    value = value.replace('Á', 'A').replace('À', 'A').replace('Ã', 'A').replace('Ä', 'A').replace('Â', 'A');
    value = value.replace('É', 'E').replace('È', 'E').replace('Ẽ', 'E').replace('Ë', 'E').replace('Ê', 'E');
    value = value.replace('Ó', 'O').replace('Ò', 'O').replace('Õ', 'O').replace('Ö', 'O').replace('Ô', 'O');
    value = value.replace('Ú', 'U').replace('Ù', 'U').replace('Ũ', 'U').replace('Ü', 'U').replace('Û', 'U');
    value = value.replace('Ç', 'C');
    value = value.replace("'", "");
    value = value.replace("'", "");
    value = value.replace("`", "");
    value = value.replace("'", "");

    return value;

  }

  const orderList = (item) => {

    const valid = {
      asc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            const nameA = removerAcentos(a.name);
            const nameB = removerAcentos(b.name);
            return nameA > nameB ? 1 : nameA < nameB ? -1 : 0
          })
        }

        return speakers;

      },
      desc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            const nameA = removerAcentos(a.name);
            const nameB = removerAcentos(b.name);

            return nameA < nameB ? 1 : nameA > nameB ? -1 : 0
          })
        }

        return speakers;

      },
    }


    if (valid[item?.order_type]) {
      item.speakers = valid[item?.order_type](item?.speakers);
    }

    return item.speakers || [];

  }

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'order_id', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      const speakers: Array<ISpeakers> = [
        {
          title: 'Palestrantes',
          speakers: [],
          position: '1',
          order_type: 'asc'
        },

      ];


      let data: Array<ISpeakers> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: projectHash, table: 'SpeakerPosition' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(!eventHash ? `/speakers-list/${projectHash}?${string}&limitItems=50` : `/speakers-list/${projectHash}/${eventHash}?${string}&limitItems=50`);

        data = response.data;
      }

      if (data) {

        let newSpeakers: Array<ISpeakers> = [];

        data.map((a: ISpeakers) => {

          a.speakers = orderList(a);

          newSpeakers.push(a);



        })

        newSpeakers.sort((a, b) => {
          return parseInt(a.position, 10) > parseInt(b.position, 10) ? 1 : parseInt(a.position, 10) < parseInt(b.position, 10) ? -1 : 0
        })



        setSpeakerData(newSpeakers);
      }
    };
    load();
  }, []);

  return speakersData.length > 0 ? <Wrapper id='palestrantes'><Container className={`shadow ${configuration?.theme_data?.speakers_className && configuration?.theme_data?.speakers_className}`}> {speakersData.map(s => <SpeakersContainer speakers={s.speakers} title={s?.[`title_${language}`] ? s?.[`title_${language}`] : s.title} />)}</Container></Wrapper> : <></>;
};

export default Speakers;

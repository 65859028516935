import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, Wrapper, ContainerWhite, Evento } from './styles';

import { SpeakerElement, SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';
import { loadFromJson } from '../../utils/loadFromJson';
import { hash } from '../../config';
import { useLanguage } from '../../hooks/Language';
import { Form } from '@unform/web';


interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id: number;
  id: number;
  category_response?: Record<any, string>;
  social?: Record<any, string>;

}

interface ISpeakers {
  title: string;
  position: string;
  order_type: string;
  speakers: Array<ISpeakerData>;
}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
const SpeakersList: React.FC = () => {
  const { translate } = useLanguage();
  const [speakersData, setSpeakerData] = useState<Array<ISpeakerData>>([]);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState(1);

  const filtered = search ? speakersData?.filter(sp => sp?.name?.toLowerCase()?.indexOf(search?.toLowerCase()?.trim()) >= 0) : speakersData;

  const { projectHash, eventHash } = useParams<IParamsURL>();
  const { language } = useLanguage();
  const { configuration } = useConfiguration();

  const addPage = (currentPage) => {

    setPage(currentPage + 1);

  }


  const removerAcentos = (value) => {

    value = value.toUpperCase();
    value = value.replace('Í', 'I').replace('Ì', 'I').replace('Ĩ', 'I').replace('Ï', 'I').replace('Î', 'I');
    value = value.replace('Á', 'A').replace('À', 'A').replace('Ã', 'A').replace('Ä', 'A').replace('Â', 'A');
    value = value.replace('É', 'E').replace('È', 'E').replace('Ẽ', 'E').replace('Ë', 'E').replace('Ê', 'E');
    value = value.replace('Ó', 'O').replace('Ò', 'O').replace('Õ', 'O').replace('Ö', 'O').replace('Ô', 'O');
    value = value.replace('Ú', 'U').replace('Ù', 'U').replace('Ũ', 'U').replace('Ü', 'U').replace('Û', 'U');
    value = value.replace('Ç', 'C');
    value = value.replace("'", "");
    value = value.replace("'", "");
    value = value.replace("`", "");
    value = value.replace("'", "");

    return value;

  }

  const orderList = (item) => {

    const valid = {
      asc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            const nameA = removerAcentos(a.name);
            const nameB = removerAcentos(b.name);
            return nameA > nameB ? 1 : nameA < nameB ? -1 : 0
          })
        }

        return speakers;

      },
      desc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            const nameA = removerAcentos(a.name);
            const nameB = removerAcentos(b.name);

            return nameA < nameB ? 1 : nameA > nameB ? -1 : 0
          })
        }

        return speakers;

      },
    }


    if (valid[item?.order_type]) {
      item.speakers = valid[item?.order_type](item?.speakers);
    }

    return item.speakers || [];

  }

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'order_id', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      const speakers: Array<ISpeakers> = [
        {
          title: 'Palestrantes',
          speakers: [],
          position: '1',
          order_type: 'asc'
        },

      ];


      let data: Array<ISpeakerData> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Speaker' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(!eventHash ? `/speakers-list/${projectHash}?${string}&limitItems=50` : `/speakers-list/${projectHash}/${eventHash}?${string}&limitItems=50`);

        data = response.data;
      }

      if (data) {

        let newSpeakers: Array<ISpeakerData> = data;



        newSpeakers.sort((a, b) => {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        })



        setSpeakerData(newSpeakers);
      }
    };
    load();
  }, []);

  return speakersData.length > 0 ? <Wrapper className='row max-1200 gap-sm' style={{ flexWrap: 'wrap' }} id='palestrantes'>
    <div className='row full-width'>
      <div className='row   max-1000' style={{ width: '100%', marginTop: '30px', justifyContent: 'space-between' }}>
        <h2 className='fadeIn' style={{ fontSize: '24px', color: '#fff', margin: '30px 0px' }} >{translate('Palestrantes')}</h2>

        <Form
          style={{ display: 'flex', alignItems: 'stretch', background: 'none' }}
          onSubmit={(data) => { setSearch(data?.pesquisa); setPage(1) }}
        >


          <input style={{ height: '43px', padding: '10px', borderRadius: '5px', border: '0px', marginRight: '10px' }}
            name="pesquisa"
            type="text"
            placeholder={`${translate('Escreva para pesquisar')}...`}
            value={search}
            onChange={e => { setSearch(e.target.value); setPage(1) }}
          />

          <button className='greenButton2' style={{ height: '43px', margin: 0 }} type="submit">{translate('Pesquisar')}</button>
        </Form>

      </div>
    </div>
    {filtered?.map((speaker, index) => {

      return index < (page * 20) ? <SpeakerElement speaker={speaker} theme={configuration?.current_event_id_response?.theme} /> : <></>

    })}
    <div className='row full-width' style={{ marginBottom: '30px' }}>
      {(page * 20) < filtered?.length ? <button onClick={() => addPage(page)} className='greenButton2'> {translate('Carregar mais')}</button> : <></>}
    </div>
  </Wrapper> : <></>;
};

export default SpeakersList;

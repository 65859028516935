import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiCopy, FiSend } from 'react-icons/fi';
import { uuid } from 'uuidv4';

interface ICreateModule {
  lineData: Record<string, any>;
  reload: Function;
}

export default function DuplicateModule({
  reload,
  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();


  const handleSend = async (): Promise<void> => {
    try {

      const item = await api.get(`/speakers_terms_response/${lineData?._id}`);
      const data = { ...item?.data };
      delete data?._id;
      delete data?.createdAt;
      data.hash = uuid();
      const response = await api.post(`/speakers_terms_response`, data);

      reload();
      addToast({ title: 'Duplicado!', type: 'success' });
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" title="Duplicar" type="button" onClick={() => handleSend()}>
      <FiCopy />
    </button>
  );

  return moduleUpdate();
}

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import Input from "../../../../components/Forms/Input";
import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useLanguage } from '../../../../hooks/Language';
import ArrayInputIncluderJoin from '../../../DashboardPages/core/components/Forms/ArrayInputIncluderJoin';
import SelectSimpleSequence from '../../../DashboardPages/core/components/Forms/SelectSimpleSequence';
import TextareaInput from '../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadCurriculumContent from '../../../DashboardPages/core/components/Forms/UploadCurriculumContent';

interface IFields {
    field: string;
    limit: number;
    label: string;
    title: string;
    description: string;
    type: string;
    options?: Array<string>;
    mask?: string;
    from: number;
    to: number;
    apply_coupon?: string;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string;
}

interface IProps {
    fields: Array<IFields>;
    applyTicketFields: Function;
    applyCoupon?: Function;
    ticket?: Record<string, any>;
    ticketIndex: number;
    ticketsInfo: Record<string, any>[];
}

const CompleteSaleFields: React.FC<IProps> = React.memo(({ ticket, ticketsInfo, ticketIndex, fields, applyTicketFields, applyCoupon = (value) => { } }) => {
    const { translate } = useLanguage();

    const formRef = useRef<FormHandles>(null);
    const readOptions = (texts) => {
        const options: Array<IOptions> = [];

        if (texts && texts.length > 0) {

            texts.map(option => {
                options.push({ label: option, value: option });
            })



        }

        return options;

    }

    const handleChange = () => {

        if (formRef.current) {

            formRef.current.submitForm();
        }
    }

    const readFields = (field: IFields, index: number) => {

        const valid = {
            select: <><p style={{ margin: '10px auto' }}>{translate(field.description)}</p><SelectSimple setCallback={(value: string) => {


                if (value !== ticket?.fields?.[field.field]) {
                    handleChange();
                }

                if (field?.apply_coupon) {
                    applyCoupon(value === 'Sim' ? field.apply_coupon : 'remove')
                }
            }} index={index} name={field.field} options={readOptions(field.options)} label={field.title} /></>,
            input: <><p style={{ margin: '10px auto' }}>{translate(field.description)}</p><Input setCallback={(value) => {
                if (value !== ticket?.fields?.[field.field]) {

                    handleChange()
                }
            }} name={field.field} placeholder={field.title} /></>,
            textarea: <TextareaInput setCallback={(value) => {
                if (value !== ticket?.fields?.[field.field]) {

                    handleChange()
                }
            }} name={field.field} placeholder={field.title} />,
            arrayInputIncluder: <><p>{field.title}</p><p>{field.description}</p><ArrayInputIncluderJoin setCallback={(value) => {
                if (value !== ticket?.fields?.[field.field]) {

                    handleChange()
                }
            }} mask={field?.mask || ''} label={field.label} limit={field.limit} name={field.field} placeholder={field.title} /></>,
            image: <><p>{field.title}</p><p>{field.description}</p><UploadCurriculumContent setCallback={(value) => {

                if (value !== ticket?.fields?.[field.field]) {

                    handleChange()
                }
            }} label={field.label} name={field.field} /></>,
            selectSequence: <SelectSimpleSequence setCallback={(value) => {
                if (value !== ticket?.fields?.[field.field]) {

                    handleChange()
                }
            }} from={field?.from} to={field.to} index={index} name={field.field} label={field.title} />,

        };

        return valid[field?.type] ? valid[field?.type] : <></>;




    }

    return fields.length > 0 ? <>

        <Form className="form"
            ref={formRef}
            initialData={{ ...ticket?.fields }}
            onSubmit={(data) => applyTicketFields(ticket?.code, data, ticketsInfo, ticket?.position)}

        >
            <p style={{ fontSize: '12px', color: '#333', marginBottom: '5px' }}>Informações adicionais do produto {ticketIndex + 1}</p>
            {
                fields.map((f, index) => {
                    return readFields(f, ((ticketIndex + 1) * 5) + index);
                })
            }

        </Form>
        <aside style={{ width: '100%', margin: '0px auto', background: '#ddd', height: '2px' }} /> </> : <></>



})


export default CompleteSaleFields;
import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, defaultProjectHash, urlWebsite } from '../../../../../../config';
import { FaLink, FaList, FaMoneyBill, FaReceipt, FaTable, FaTicketAlt, FaUpload, FaUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';
import CopyToClipboard from '../../../../core/components/CopyToClipboard';
import WhatsappCompartilhar from '../../../../../../components/WhatsAppCompartilhar';
import WhatsappCompartilharSimple from '../../../../../../components/WhatsAppCompartilharSimple';
import { FormHandles } from '@unform/core';
import FormComponent from 'src/pages/DashboardPages/core/components/FormComponent';

interface ICreateModule {
    lineData: Record<string, any>;
}

export const UpdateStudents = () => {
    const { user } = useAuth();
    const { addToast } = useToast();
    const { addModal } = useModal();
    const { projectHash } = useParams<Record<string, any>>()


    const updateUsers = async (response, students) => {

        let x = 0;

        while (x < response?.data?.rows?.length) {
            if (x > 3383) {
                const item = response?.data?.rows[x];

                const student = students?.data?.rows?.find(i => i.email?.trim()?.toLowerCase() === item?.email?.trim()?.toLowerCase());



                let phone = students?.phone || item?.phone?.toString().replace(/[^0-9]/g, '');

                if (student) {

                    await Promise.all([
                        api.put(`/students/${student?._id?.toString()}/phone`, { phone: phone }),
                        api.put(`/students/${student?._id?.toString()}/city`, { city: item?.city }),
                        api.put(`/students/${student?._id?.toString()}/state`, { state: item?.state }),
                        api.put(`/students/${student?._id?.toString()}/country`, { country: item?.country }),
                        api.put(`/students/${student?._id?.toString()}/know_about`, { know_about: item?.know_about }),
                        api.put(`/students/${student?._id?.toString()}/company`, { company: item?.company }),
                        api.put(`/students/${student?._id?.toString()}/position`, { position: item?.position }),
                        api.put(`/students/${student?._id?.toString()}/category_id`, { category_id: item?.category_id })


                    ])

                    addToast({ title: `Atualizado ${item?.name} - ${x + 1}/${response?.data?.rows?.length}`, type: 'info' })
                    console.log('existente', student?._id, student?.name)
                }
                else {

                    const data = {

                        project_id: 'portal-bariatric-channel',
                        name: item?.name,
                        email: item?.email?.trim()?.toLowerCase(),
                        document_number: item?.document_number,
                        phone: item?.phone?.toString().replace(/[^0-9]/g, ''),
                        extra_1: item?.extra_1,
                        city: item?.city,
                        state: item?.state,
                        country: item?.country,
                        language: item?.language,
                        category_id: item?.category_id,
                        company: item?.company,
                        position: item?.position,
                        know_about: item?.know_about,
                        term: item?.term


                    }
                    console.log('novo', data)
                    await api.post(`/students`, { ...data });
                    addToast({ title: `Adicionado ${data?.name} - ${x + 1}/${response?.data?.rows?.length}`, type: 'info' })
                }
            }
            x += 1;
        }

    }

    const printLine = (item, students) => {

        const student = students?.data?.rows?.find(i => i.email?.trim()?.toLowerCase() === item?.email?.trim()?.toLowerCase());

        return <tr>
            <td>{student?._id?.toString() || 'Não encontrado'}</td>
            <td>{student?.name || item?.name}</td>
            <td>{student?.email?.trim()?.toLowerCase() || item?.email?.trim()?.toLowerCase()}</td>
            <td>{student?.document_number || item?.document_number}</td>
            <td>{student?.phone || item?.phone}</td>
            <td>{item?.extra_1}</td>
            <td>{item?.city}</td>
            <td>{item?.state}</td>
            <td>{item?.country}</td>
            <td>{item?.language}</td>
            <td>{item?.category_id}</td>
            <td>{item?.company}</td>
            <td>{item?.position}</td>
            <td>{item?.know_about}</td>
            <td>{item?.term}</td>

        </tr>


    }

    const setCallback = async (
        data: Record<string, any>,
        formRef: React.RefObject<FormHandles>,
    ): Promise<void> => {
        try {
            const formData = new FormData();
            formData.append('file', data.image)
            const response = await api.post(`/converter/xlsx-to-json`, formData);

            const students = await api.get('/students', { params: { limitItems: 100000 } })

            addModal({
                theme: 'whiteModal', content: <><button className='defaultButton' onClick={() => { updateUsers(response, students) }}>Atualizar</button> <table className='table'>

                    <tr> <td>ID</td>
                        <td>Nome</td>
                        <td>E-mail</td>
                        <td>Documento</td>
                        <td>Telefone</td>
                        <td>Data de nascimento</td>
                        <td>Cidade</td>
                        <td>UF</td>
                        <td>País</td>
                        <td>Idioma</td>
                        <td>Categoria</td>
                        <td>Empresa</td>
                        <td>Cargo</td>
                        <td>Como conheceu</td>
                        <td>Termo</td>

                    </tr>

                    {response?.data?.rows?.map(i => {
                        return printLine(i, students)
                    })}


                </table></>, title: ''
            });

        } catch (err) {
            console.log(err);

            return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
        }
    };

    const handleCreate = (): void => {
        const content = (
            <FormComponent schema={{
                image: {
                    model: 'uploadImage',
                    type: 'upload',
                    name: 'image',
                    label: 'Imagem',
                }
            }} setCallback={setCallback} />
        );
        if (content) {
            const keyModal = addModal({ title: 'Adicionar usuários', content, key: 'modal', theme: "whiteModal" });
        }
    };

    const moduleCreate = (): JSX.Element => (
        user && user?.profile === 1 ? <button className="searchButton" type="button" onClick={handleCreate}>
            <FaUpload />
        </button> : <></>
    );

    return moduleCreate();
}
export default UpdateStudents;
import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

const fadeIn = keyframes`
  0% {
    opacity : 0;
    filter: blur(20deg);
    transform : translateY(30px);
  }

  20% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }
  100% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }


`;

const appear = keyframes`
  0% {
    
    filter: blur(20deg);
    transform : scale(0) translateY(30px);
  }

  20% {
    opacity: 1;
    filter: blur(0deg);
    transform : scale(1) translateY(0px);
  }
  100% {
    opacity: 1;
    filter: blur(0deg);
    transform : scale(1) translateY(0px);
  }


`;

const apperOut = keyframes`
  0% {

    filter: blur(0deg);
    opacity:1 ;
    transform : scale(1) translateY(0px);
  }
  20% {

    filter: blur(0deg);
    opacity:0 ;
    transform :scale(1) translateY(30px);
  }
  100% {
    opacity:0 ;
    filter: blur(20deg);
    transform : translateY(30px);
  }

`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }
  80% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }
  100% {
    opacity: 0;
    filter: blur(20deg);
    transform : translateY(-30px);
  }

`;

export const Container = styled.div<IBackground>`
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
background: green;

.holder {
  position: absolute;
  bottom: 50px;
  left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}


.fadeIn {
   

  .lowerImage{
    animation: ${appear} 1s forwards;
  }
  .lowerContent{
    h2{
 
    animation: ${appear} 1.5s forwards;
    }

    strong{
     
    animation: ${appear} 2s forwards;
    }
   
  }
}

.fadeOut {
  


  .lowerImage{
    animation-delay: 1s ;
    animation: ${apperOut} 2s forwards;
  }
  .lowerContent{
    h2{
      animation-delay: 0.5s ;
    animation: ${apperOut} 1.5s forwards;
    }

    strong{
     
      animation: ${apperOut} 1s forwards;
    }
   
  }
}

.SimpleButton { 
 padding: 10px;
 margin: 5px;
 border-radius: 20px;
font-size: 14px;
border: 0px;
 background: none;
 color: #fff;
}

.lowerExample{
  background: #fff;
  margin: 5px;
  border-radius: 10px;
  color: #333 !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  padding: 10px;
  cursor: pointer;

  p,strong{
    color: #333 !important;
  }
}

.messageContent{
  cursor: pointer;
}

.lower{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: none;
  color: #333;
  
  padding: 20px;
  min-height: 100px;

 
  gap: 15px;

  >h2{
    font-size: 24px;
    color: #A71C20 ;
    max-width: 500px;
    background: #fff;
    padding: 10px 15px;
    margin: 3px 0px;
  }

  >strong{
    margin: 3px 0px;
    color: #fff;
    max-width: 500px;
    background: #A71C20 ;
    padding: 10px 15px;
  }

.lowerImage{
  width: 120px;
  padding: 15px;
  background: #fff ;
  border-radius: 10px;
  //box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
  >img{
    width: 100%;
  }
}

.lowerContent{
  display: flex ;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column !important;
  

  >h2{
    font-size: 24px;
    color: #A71C20 ;
    max-width: 500px;
    background: #fff;
    padding: 10px 15px;
   // box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    margin: 3px 0px;
  }

  >strong{
    margin: 3px 0px;
    color: #fff;
    max-width: 500px;
    background: #A71C20 ;
    padding: 10px 15px;
  //  box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
  }

  >strong, >p{
    font-size: 18px;
    font-weight: bold;
 
    
  }
}
}

`;

export const Grid = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;

  >div {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border: 1px solid #fff;
    border-radius: 10px;
   // box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  
  >svg{
    color: #fff;
    font-size: 24px;
  }
  }

`;

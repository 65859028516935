import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../../../hooks/Language';
import CreditCardForm from './modules/CreditCard';
import GeneralForm from './modules/GeneralForm';
import CurrencyInput from '../../../../../../components/Forms/MaskedCurrencyInput';
import CompleteSaleFields from '../../../CompleteSaleFields';
import { FiArrowRight, FiTarget } from 'react-icons/fi';
import calculateDiscount from '../../Functions/calculateDiscount';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../components/Forms/SelectSimple';
import { countries } from '../../../Login/countries';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaKey } from '../../../../../../config';
import getCep from '../../../../../../services/cep';
import { FormHandles } from '@unform/core';
import { FaCheckCircle, FaHandHolding, FaHandPointer, FaHandPointRight, FaPlus, FaPortrait } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useToast } from '../../../../../../hooks/Toast';
interface IProps {
    ticketList: Array<Record<string, any>>;
    applyTicketInfo: Function;
}


const escolaridade = [

    { label: "ANALFABETO", value: "ANALFABETO" },
    { label: "PRIMÁRIO INCOMPLETO (ATÉ 4º)", value: "PRIMÁRIO INCOMPLETO (ATÉ 4º)" },
    { label: "PRIMÁRIO COMPLETO (ATÉ 4º)", value: "PRIMÁRIO COMPLETO (ATÉ 4º)" },
    { label: "ENSINO FUNDAMENTAL INCOMPLETO", value: "ENSINO FUNDAMENTAL INCOMPLETO" },
    { label: "ENSINO FUNDAMENTAL COMPLETO", value: "ENSINO FUNDAMENTAL COMPLETO" },
    { label: "ENSINO MÉDIO INCOMPLETO", value: "ENSINO MÉDIO INCOMPLETO" },
    { label: "ENSINO MÉDIO COMPLETO", value: "ENSINO MÉDIO COMPLETO" },
    { label: "ENSINO SUPERIO INCOMPLETO", value: "ENSINO SUPERIO INCOMPLETO" },
    { label: "ENSINO SUPERIOR COMPLETO", value: "ENSINO SUPERIOR COMPLETO" },
    { label: "PÓS GRADUAÇÃO - ESPECIALIZAÇÃO", value: "PÓS GRADUAÇÃO - ESPECIALIZAÇÃO" },
    { label: "MESTRADO", value: "MESTRADO" },
    { label: "DOUTORADO", value: "DOUTORADO" }
]


const unimeds = [
    { label: "INSTITUTO UNIMED", value: "INSTITUTO UNIMED" },
    { label: "UNIAIR TRANSPORTE AEROMÉDICO", value: "UNIAIR TRANSPORTE AEROMÉDICO" },
    { label: "UNICOOPMED", value: "UNICOOPMED" },
    { label: "UNIMED ALEGRETE/RS", value: "UNIMED ALEGRETE/RS" },
    { label: "UNIMED ALTO DA SERRA", value: "UNIMED ALTO DA SERRA" },
    { label: "UNIMED ALTO JACUÍ/RS", value: "UNIMED ALTO JACUÍ/RS" },
    { label: "UNIMED ALTO URUGUAI/RS", value: "UNIMED ALTO URUGUAI/RS" },
    { label: "UNIMED CENTRAL DE SERVIÇOS – RS", value: "UNIMED CENTRAL DE SERVIÇOS – RS" },
    { label: "UNIMED CENTRO/RS", value: "UNIMED CENTRO/RS" },
    { label: "UNIMED ENCOSTA DA SERRA/RS", value: "UNIMED ENCOSTA DA SERRA/RS" },
    { label: "UNIMED ERECHIM", value: "UNIMED ERECHIM" },
    { label: "UNIMED FRONTEIRA NOROESTE/RS", value: "UNIMED FRONTEIRA NOROESTE/RS" },
    { label: "UNIMED ITAQUI RS", value: "UNIMED ITAQUI RS" },
    { label: "UNIMED LITORAL SUL/RS", value: "UNIMED LITORAL SUL/RS" },
    { label: "UNIMED MISSÕES/RS", value: "UNIMED MISSÕES/RS" },
    { label: "UNIMED NORDESTE RS", value: "UNIMED NORDESTE RS" },
    { label: "UNIMED NOROESTE/RS", value: "UNIMED NOROESTE/RS" },
    { label: "UNIMED PELOTAS/RS", value: "UNIMED PELOTAS/RS" },
    { label: "UNIMED PLANALTO CENTRAL/RS", value: "UNIMED PLANALTO CENTRAL/RS" },
    { label: "UNIMED PLANALTO MÉDIO", value: "UNIMED PLANALTO MÉDIO" },
    { label: "UNIMED PORTO ALEGRE", value: "UNIMED PORTO ALEGRE" },
    { label: "UNIMED REGIÃO DA CAMPANHA/RS", value: "UNIMED REGIÃO DA CAMPANHA/RS" },
    { label: "UNIMED REGIÃO DA FRONTEIRA/RS", value: "UNIMED REGIÃO DA FRONTEIRA/RS" },
    { label: "UNIMED REGIÃO DA PRODUÇÃO/RS", value: "UNIMED REGIÃO DA PRODUÇÃO/RS" },
    { label: "UNIMED SANTA MARIA/RS", value: "UNIMED SANTA MARIA/RS" },
    { label: "UNIMED URUGUAIANA/RS", value: "UNIMED URUGUAIANA/RS" },
    { label: "UNIMED VALE DAS ANTAS, RS", value: "UNIMED VALE DAS ANTAS, RS" },
    { label: "UNIMED VALE DO CAÍ/RS", value: "UNIMED VALE DO CAÍ/RS" },
    { label: "UNIMED VALE DO SINOS", value: "UNIMED VALE DO SINOS" },
    { label: "UNIMED VALES DO TAQUARI E RIO PARDO", value: "UNIMED VALES DO TAQUARI E RIO PARDO" },
    { label: "OUTROS", value: "OUTROS" },
];

interface IPropsTickets {
    index: number;
    code: string;
    setCallback: Function;
    tickets: Array<Record<string, any>>;
    position: number;
}

const TicketFormContainer: React.FC<IPropsTickets> = ({ index, code, setCallback, tickets, position }) => {
    const formRef = useRef<FormHandles>(null);

    const ticket = tickets?.find(i => i?.code === code) || {};
    const [countryISO, setCountryISO] = useState('BR');
    const { translate } = useLanguage();
    const [company, setCompany] = useState('');
    const [active, setActive] = useState(false);
    const { addModal, removeModal } = useModal();
    const { addLoading, removeLoading } = useLoading();
    const { addToast } = useToast();

    const validate = async (data) => {
        addLoading({ title: 'validando', key: 'validando' });
        try {
            formRef.current?.setErrors({});


            const schema = Yup.object().shape({
                name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
                email: Yup.string().email().required(`${translate('E-mail')} ${translate('obrigatório')}`),
                phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
                document_number: Yup.string()
                    .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
                    .required(`${translate('CPF')} ${translate('obrigatório')}`),



            });


            data.document_number = data?.document_number?.toString()?.replace(/[^0-9]/g, '');


            await schema.validate(data, { abortEarly: false });
            setCallback(data);
            removeLoading('validando');
            removeModal('modal');
        }
        catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                const errorInfo = err.errors.reduce((error, prev) => {
                    prev = `${prev}, ${error}`;
                    return prev;
                });
                removeLoading('validando')
                addToast({
                    type: 'error',
                    title: 'Erro de validação',
                    description: errorInfo,
                });
            }
            removeLoading('validando')
        }




    }

    const validateToMe = async () => {
        addLoading({ title: 'validando', key: 'validando' });

        /*  const index = tickets?.findIndex(i => i?.userData?.type === 'self');
 
         if (index >= 0) {
             removeLoading('validando');
             removeModal('modal');
             addToast({ title: 'Você só pode atribuir 1 ingresso ao seu cadastro.', type: 'error' });
             return;
         } */
        removeLoading('validando');
        removeModal('modal');
        setCallback({ type: 'self' });


    }


    const openModalCheck = () => {

        addModal({
            key: 'modal',
            theme: 'whiteModalMedium',
            title: '',
            content: <>

                <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Cadastrar ingresso</h2>
                <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button onClick={() => validateToMe()} className='greenButton2 buttonColored'>Para o meu cadastro</button>
                </aside >

                <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>OU</h2>

                <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button onClick={() => openModal()} className='greenButton2 buttonColored'>Para outro participante</button>
                </aside >
            </>
        })



    }

    const openModal = () => {

        addModal({
            key: 'modal',
            theme: 'whiteModalMedium',
            title: '',
            content: <> <Form key={ticket?.code} className="form"
                ref={formRef}
                initialData={{ country: "BR", ...ticket?.userData }}
                onSubmit={(data) => validate(data)}

            >
                <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Dados do participante</h2>
                <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button className='greenButton2 buttonColored'>Salvar</button>
                </aside >



                <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

                <Input type='text' name='name' placeholder='Nome completo' />
                <Input type='text' name='email' placeholder='E-mail' />
                {countryISO === 'BR' ? <MaskedInput
                    mask="999.999.999-99"
                    name="document_number"
                    placeholder={translate('CPF')}
                /> : <Input name="document_number"
                    placeholder={translate('Nº do documento')} />}

                {countryISO === 'BR' ? <MaskedInput mask='(99) 99999.9999' type='text' name='phone' placeholder='Celular / Whatsapp' />
                    : <Input name="phone"
                        placeholder={translate('Número do telefone')} />}

                <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button className='greenButton2 buttonColored'>Salvar</button>
                </aside>


            </Form></>
        })


    }



    return <> <div className='row scaleOnHover ' style={{ borderRadius: '5px', border: '1px solid #ddd', padding: '5px 0px', cursor: 'pointer', width: '100%', justifyContent: 'flex-start', alignItems: 'stretch' }}
        onClick={() => openModalCheck()}>
        <div style={{ width: '40px', borderRight: '2px dashed #333', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FaPortrait style={{ color: 'rgb(0,102,178)', fontSize: '24px' }} />
        </div>

        <div className='column full-width'><h2 style={{ fontSize: '14px', margin: ' 0px', width: '100%', color: '#333', padding: '0px', textAlign: 'left' }}>{ticket?.product?.title} </h2>

            <p style={{ fontSize: '14px', margin: ' 0px', width: '100%', color: '#333', padding: '0px', textAlign: 'left' }}> {ticket?.userData?.type === 'self' ? translate('Atribuido ao seu cadastro') : ticket?.userData?.name ? ticket?.userData?.name : translate('Adicionar participante')}  </p>
        </div>
        <div style={{ width: '40px', borderLeft: '2px dashed #333', marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {ticket?.validated === true ? <FaCheckCircle style={{ color: 'green' }} /> : <FaPlus />}
        </div>

    </div>


    </>
}


const TicketForm: React.FC<IProps> = ({ ticketList, applyTicketInfo }) => {
    const { translate } = useLanguage();


    return <>
        <p style={{ color: '#333', fontSize: '12px', margin: '5px 0px' }}><span style={{ color: "rgb(0,102,178)", fontWeight: 'bold' }}><strong>{translate("Clique no ingresso")}</strong></span> {translate("para alterar o convidado")}</p>
        {ticketList?.map((item, index) => {

            return <TicketFormContainer code={item?.code} position={item?.position} index={index} tickets={ticketList} setCallback={(data) => applyTicketInfo(item?.code, data, ticketList, item?.position)} />


        })}

    </>

};
export default TicketForm;

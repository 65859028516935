import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 500px;

  background: #fff;
  border-radius: 15px;
  max-width: 900px !important;
  padding: 20px 20px;
  color: #333;
  min-width: 50vh !important;

  .PaymentMethodSelector {
    display: flex; width: 100%; max-width: 100%; flex-direction: column; justify-content: center; align-items: center;
 position: relative ;
    margin-top: 20px;
  //  background: #eee ;
    border-radius: 10px;
    padding: 15px ;
    border: 2px solid #A71C20 !important;

    @media (max-width: 750px) {
      margin-top: 0px;
  }
  }

  .PaymentMethodContainer{
    display: flex ;
     align-items: flex-start;
     justify-content: center;
     gap: 35px;
     flex-wrap: wrap;

     @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
     justify-content: flex-start;
     gap: 5px;
  }

  >div{
    max-width: 340px !important;
    min-width: 300px !important;
  }
  }

  z-index: 1;
  aside {
    display: flex;
    flex-direction: row;

    form{
      margin: 0px !important;
    }

    > button.active {
      box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    }
    > button {
      padding: 10px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
      margin: 20px;
      max-width: 200px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 0;
      > img {
        width: 100%;
        max-width: 200px;
      }

      > span {
        font-weight: bold;
      }

      > input {
        min-width: 25px;
        min-height: 25px;
        width: 25px;
        height: 25px;
        margin-right: 15px;
      }
    }
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .active {
    border: 2px solid #67bfab;
    box-shadow: 0px 0px 8px #67bfab !important;
  }
`;

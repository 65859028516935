import React, { useEffect, useState } from 'react';
import { addHours, getDate, getDay, getMonth } from "date-fns"
import { monthNumber, simpleDateBR, simpleDateUS } from "../../../../../../utils/date";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Chart from '../../../../../../components/ChartModule';
import { FaCalendar, FaChartBar, FaChartPie, FaCheck, FaCreditCard, FaFileExcel, FaFilter, FaTicketAlt } from 'react-icons/fa';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInputSearch';
import { useModal } from '../../../../../../hooks/Modal';
import { FiFilter } from 'react-icons/fi';
import { convertToXLSX } from '../../../../../../utils/download';
import api from '../../../../../../services/api';
import { string } from 'yup';
import { useParams } from 'react-router-dom';
interface IProps {
    sales?: Array<Record<string, any>>,
    salesProducts?: Array<Record<string, any>>,
    students?: Array<Record<string, any>>,
    products?: Array<Record<string, any>>,
}

interface IOption {
    id: string;
    label: string;
    value: string;
}


const convert = (list: Array<Record<string, any>>, group = false) => {

    const items: Array<Array<string>> = [];


    if (group) {
        items.push(['Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Indicado por']);
        list?.map(item => {

            items.push([item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', item?.seller_hash]);

        })
    }
    else {
        items.push(['Produto', 'Transferido?', 'Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Indicado por']);
        list?.map(item => {

            items.push([item?.product, item?.haveClientID, item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', item?.seller_hash]);

        })
    }


    convertToXLSX(items);

}

const ListCourtesies: React.FC<IProps> = ({ sales, salesProducts = [], students, products = [] }) => {

    const [courtesies, setCourtesies] = useState<Record<string, any>[]>([]);

    const { projectHash } = useParams<Record<string, any>>();



    // Define the target time zone as GMT-3
    const targetTimeZone = 'America/Sao_Paulo'; // Example for GMT-3, adjust as necessary

    const load = async () => {


        const items = await api.get('/v3/sales-cortesy', { params: { limitItems: 1000, where: { project_id: projectHash } } })
        setCourtesies(items?.data?.rows)
    }

    useEffect(() => {
        load();

    }, [])


    const list = courtesies?.reduce((prev, item) => {

        const used = salesProducts?.filter(i => i?.cortesy_hash_id === item?.hash && i?.client_id)?.length;

        prev.push({ title: item?.title, product: item?.product_id_response?.title, quantity: item?.quantity, used: used, available: item?.quantity - used });
        return prev;

    }, []);



    const sortedList = list.sort((a, b) => a.title < b.title ? -1 : a.title > b.title ? 1 : 0);

    const productList = products ? products?.reduce((prev: Array<IOption>, item): Array<IOption> => {
        prev.push({ label: item?.title, value: item?._id?.toString(), id: item?._id?.toString() });
        return prev;
    }, [] as Array<IOption>) : [];



    return <>


        <table className="tableRef" style={{ width: '100%', margin: '0px', maxWidth: '100%' }}>


            {sortedList?.map(item => {
                return <tr key={`resumo-vendas-${item?._id?.toString()}`}>
                    <td>

                        <div>
                            <p style={{ color: '#333', fontWeight: 'bold', fontSize: '12px' }}>
                                {item?.title}
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ color: '#333', fontSize: '12px' }}>
                                {item?.product}
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                <div title='Quantidade' style={{ background: 'rgb(0,100,150)', width: '30px', height: '30px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ color: '#fff', fontSize: '12px' }}> {item?.quantity}</p>
                                </div>
                                <div title='Utilizados' style={{ background: 'rgb(200,50,50)', width: '30px', height: '30px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ color: '#fff', fontSize: '12px' }}> {item?.used}</p>
                                </div>
                                <div title='Disponíveis' style={{ background: 'rgb(0,150,100)', width: '30px', height: '30px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ color: '#fff', fontSize: '12px' }}> {item?.available}</p>
                                </div>
                            </div>
                        </div>
                    </td>


                </tr>
            })}

        </table>


    </>


}

export default ListCourtesies;
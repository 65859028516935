import styled, { css } from "styled-components";
import { EditableDiv } from "../../styles";
interface IEditableDiv {
    computer: Record<string, any>;
    mobile: Record<string, any>;
}


export const TitleStyle = styled.h2<IEditableDiv>`


font-size: 32px;
line-height: 36px;
text-align: left;
width: 100%;

${props => props?.computer && Object.keys(props?.computer)?.map(key => {

    return css`
${key} : ${props?.computer?.[key]} !important;

`


})}


@media (max-width: 750px) {
${props => props?.mobile && Object.keys(props?.mobile)?.map(key => {

    return css`
${key} : ${props?.mobile?.[key]} !important;

`


})}
}

`;

export const SubTitleStyle = styled.h3<IEditableDiv>`


font-size: 24px;
line-height: 28px;
text-align: left;
width: 100%;

${props => props?.computer && Object.keys(props?.computer)?.map(key => {

    return css`
${key} : ${props?.computer?.[key]} !important;

`


})}


@media (max-width: 750px) {
${props => props?.mobile && Object.keys(props?.mobile)?.map(key => {

    return css`
${key} : ${props?.mobile?.[key]} !important;

`


})}
}

`;

export const ParagraphStyle = styled.p<IEditableDiv>`


font-size: 14px;
line-height: 18px;
text-align: left;
width: 100%;

${props => props?.computer && Object.keys(props?.computer)?.map(key => {

    return css`
${key} : ${props?.computer?.[key]} !important;

`


})}


@media (max-width: 750px) {
${props => props?.mobile && Object.keys(props?.mobile)?.map(key => {

    return css`
${key} : ${props?.mobile?.[key]} !important;

`


})}
}

`;


export const ImageStyle = styled.img<IEditableDiv>`

 
width: 100%;

${props => props?.computer && Object.keys(props?.computer)?.map(key => {

    return css`
${key} : ${props?.computer?.[key]} !important;

`


})}


@media (max-width: 750px) {
${props => props?.mobile && Object.keys(props?.mobile)?.map(key => {

    return css`
${key} : ${props?.mobile?.[key]} !important;

`


})}
}

`;

export const VideoStyle = styled(EditableDiv)`

 position: relative;
width: 100%;

`;
import { useEffect, useState } from "react";
import { useLoading } from "../../../../../hooks/LoadingHook";
import { useModal } from "../../../../../hooks/Modal";
import { useToast } from "../../../../../hooks/Toast";
import useSendPaymentRequest from "./paymentFunctions/sendPaymentRequest";
import useFieldsList from "./salesFields/salesFields";

type IPaymentMethod = 'credit_card' | 'boleto' | 'pix' | 'cortesy' | 'gratuito';

export default function usePaymentContext() {
    /* this hook manage the payment request */

    const [installmentValue, setInstallmentValue] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>('credit_card');

    const { removeLoading } = useLoading();
    const { checkFieldListValidation, fieldsListGetData } = useFieldsList();
    const { finishPaymentRequest } = useSendPaymentRequest();

    const [cardInfo, setCardInfo] = useState({
        card_number: '',
        card_holder_name: '',
        card_expiration_date: '',
        card_cvv: ''
    })
    const { removeModal } = useModal();
    const { addToast } = useToast();
    const changeCardInfo = (property, value) => {

        setCardInfo({ ...cardInfo, [property]: value })

    }




    const changePaymentMethod = (newPaymentMethod) => {
        if (newPaymentMethod === 'creditCard') {
            setPaymentMethod('credit_card');
        }
        else {
            setPaymentMethod(newPaymentMethod);
            setInstallmentValue(1);
        }
    }

    const changeInstallment = (newInstallment) => {
        setInstallmentValue(newInstallment);
    }

    const sendPaymentRequest = async ({ check, moveToCart, data, cart, ticketsInfo, paymentMethod, coupon, courtesy, total, price, discount }) => {

        /*
        1. verify payment_method
          1.1 if credit_card, check card validation
        2. check sales_fields
        3. prepare call
        4. send to confirmation
        */

        let fields = {};
        let fields_text = '';

        ticketsInfo?.map(i => {

            if (i?.fields) {
                fields = { ...fields, ...i?.fields }
            }
            if (i?.fields_text) {
                fields_text = `${fields_text},${i?.fields_text}`;
            }
        })

        data.fields = fields;
        data.fields_text = fields_text;

        const paymentContext = {
            ...data,
            check,
            card: { ...cardInfo },
            paymentMethod: total === 0 ? 'gratuito' : paymentMethod,
            cart,
            tickets_info: ticketsInfo,
            coupon,
            cortesy_hash: courtesy,
            priceInformation: {
                total,
                discount,
                price
            },
            billing: {
                document_type: data?.document_type,
                paymentMethod: paymentMethod,
                installments: installmentValue
            }
        };


        let ticketsCheckIndex = 0;
        let checkValidation = true;

        while (ticketsCheckIndex < ticketsInfo?.length) {
            const ticket = ticketsInfo[ticketsCheckIndex];

            if (ticket?.product?.fields?.length > 0) {

                try {
                    const checkList = await checkFieldListValidation({ product: ticket?.product || {}, data: ticket?.fields, index: ticketsCheckIndex })
                    if (checkList === 'error') {
                        checkValidation = false;
                    }

                }
                catch (err) {
                    removeLoading('validando');
                    addToast({ title: 'Erro ao validar dados', type: 'error' })
                    checkValidation = false;
                    return;

                }


            }
            ticketsCheckIndex += 1;

        }

        if (!checkValidation) {
            console.log('Deu erro');
            removeLoading('validando')
            return;
        }


        await finishPaymentRequest(paymentContext, moveToCart)



    }

    return { installmentValue, changeInstallment, paymentMethod, changePaymentMethod, sendPaymentRequest, changeCardInfo };

}

import React, { useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { useLanguage } from 'src/hooks/Language';
import { useModal } from 'src/hooks/Modal';
import CounterCardRoomSocket from 'src/pages/DashboardPages/core/components/CounterCardRoomSocket';
import RoomConfiguration from '../ContentClassRoom/modules/RoomConfigurations';
import { SidebarContainer } from './styles';

const SidebarClassroom: React.FC<Record<string, any>> = React.memo(({ courseHook }) => {

    const { language } = useLanguage();
    const { sidebarContent, selectContent, backToStart, content } = courseHook;
    const [active, setActive] = useState(false);
    const { addModal, removeModal } = useModal();





    return <SidebarContainer className='column computerOnly ' style={{ width: '100%', marginTop: '32px' }}>


        {sidebarContent?.map(sidebarModule => {

            return <div className='full-width  column computerOnly'>
                <h2 className='fadeIn'>{sidebarModule?.title}</h2>
                <div>
                    {sidebarModule?.content?.map(contentItem => {

                        return <nav className={`fadeIn ${content?._id === contentItem?._id ? 'activeSidebarMenu' : ''}`} onClick={() => {
                            selectContent(contentItem?._id)
                        }}>{contentItem?.[`title_${language}`] ? contentItem?.[`title_${language}`] : contentItem?.title}</nav>


                    })}
                </div>

            </div>


        })}



    </SidebarContainer>



});

export default SidebarClassroom;
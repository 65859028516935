import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { useLanguage } from '../../../../../../hooks/Language';
import CreditCardForm from './modules/CreditCard';
import GeneralForm from './modules/GeneralForm';
import CurrencyInput from '../../../../../../components/Forms/MaskedCurrencyInput';
import CompleteSaleFields from '../../../CompleteSaleFields';
import { FiArrowRight } from 'react-icons/fi';
import calculateDiscount from '../../Functions/calculateDiscount';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../components/Forms/SelectSimple';
import { countries } from '../../../Login/countries';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaKey } from '../../../../../../config';
import getCep from '../../../../../../services/cep';
import { FormHandles } from '@unform/core';
import DisplayCartPrice from '../../../../../Products/modules/PaymentMethod/modules/DisplayCartPrice';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { useToast } from '../../../../../../hooks/Toast';
interface IProps {
    paymentMethod: 'credit_card' | 'boleto' | 'pix' | 'cortesy';

    cart: Array<Record<string, any>>;
    couponInfo?: Record<string, any>;
    changeInstallment: Function;
    installmentValue: number;
    quantity: number;
    sendPaymentRequest: Function;
    applyCoupon: Function;
    courtesyCode: string;
}

const PaymentMethodForm: React.FC<IProps> = ({ courtesyCode, paymentMethod, couponInfo, cart, changeInstallment, installmentValue, quantity, sendPaymentRequest, applyCoupon }) => {

    const { translate } = useLanguage();
    const product = cart?.[0] || {};
    const recaptchaRef = useRef(null);
    const [type, setType] = useState('cpf');
    const [countryISO, setCountryISO] = useState('BR');
    const [company, setCompany] = useState('');
    const formRef = useRef<FormHandles>(null);
    const { addLoading, removeLoading } = useLoading();
    const { addToast } = useToast();

    const paymentMethodElements = {

        boleto: () => <GeneralForm />,
        pix: () => <GeneralForm />,
        cortesy: () => <></>,
        courtesy: () => <></>,
    }

    const getCaptchaValue = (elem: any): string => {
        return elem?.getValue ? elem?.getValue() : '';
    };


    const getCepFromApi = async (value: string): Promise<void> => {
        const cepResponse = await getCep(value);

        if (!cepResponse.error) {
            formRef?.current?.setFieldValue('address', cepResponse.logradouro);
            formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
            formRef?.current?.setFieldValue('city', cepResponse.localidade);
            formRef?.current?.setFieldValue('state', cepResponse.uf);
        }
    };



    const validate = async (data) => {
        addLoading({ title: 'validando', key: 'validando' });
        try {
            formRef.current?.setErrors({});


            const schema = Yup.object().shape({
                name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
                email: Yup.string().email().required(`${translate('E-mail')} ${translate('obrigatório')}`),
                phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
                document_number: Yup.string()
                    .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
                    .required(`${translate('CPF')} ${translate('obrigatório')}`),
            });


            data.document_number = data?.document_number?.toString().replace(/[^0-9]/g, '') || '';
            data.company_document_number = data?.company_document_number?.toString().replace(/[^0-9]/g, '') || '';


            await schema.validate(data, { abortEarly: false });




            sendPaymentRequest({ data, paymentMethod: calculateDiscount(couponInfo, cart).discountedValue > 0 ? paymentMethod : 'boleto', cart, coupon: couponInfo, courtesy: courtesyCode });
            removeLoading('validando');

        }
        catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                const errorInfo = err.errors.reduce((error, prev) => {
                    prev = `${prev}, ${error}`;
                    return prev;
                });
                removeLoading('validando')
                addToast({
                    type: 'error',
                    title: 'Erro de validação',
                    description: errorInfo,
                });
            }
            console.log(err)
            removeLoading('validando')
        }




    }



    return <>

        <Form className="form"
            ref={formRef}
            initialData={{ country: "BR", installments: installmentValue, quantity: quantity, document_type: type }}
            onSubmit={(data) => { data.recaptcha = getCaptchaValue(recaptchaRef?.current); validate(data) }}

        >


            {product.fields && product.fields.length > 0 ? <>  <CompleteSaleFields applyCoupon={applyCoupon} fields={product.fields} />
                <br />
            </> : <></>}

            <div className='outerBlock'>
                <h2 style={{ margin: ' 0px', fontSize: '14px', marginBottom: '5px', width: '100%', color: '#333', borderBottom: '3px solid #0a4f4b', padding: '5px 0px', textAlign: 'left' }}>{translate('4. Dados do Inscrito(a)')}</h2>
                <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

                <Input type='text' name='name' placeholder='Nome completo' />
                <Input type='text' name='email' placeholder='E-mail' />
                {countryISO === 'BR' ? <MaskedInput
                    mask="999.999.999-99"
                    name="document_number"
                    placeholder={translate('CPF')}
                /> : <Input name="document_number"
                    placeholder={translate('Nº do documento')} />}




                {countryISO === 'BR' ? <MaskedInput mask='(99) 99999.9999' type='text' name='phone' placeholder='Celular / Whatsapp' />
                    : <Input name="phone"
                        placeholder={translate('Número do telefone')} />}

                <Input type='text' name='disability_id' placeholder='Você é uma pessoa com deficiência? Se sim, especifique:' />


            </div>

            <div style={{ marginTop: '10px' }} />



            <div className='outerBlock'>

                <h2 style={{ margin: ' 0px', fontSize: '14px', marginBottom: '5px', width: '100%', color: '#333', borderBottom: '3px solid #A71C20', padding: '5px 0px', textAlign: 'left' }}>{translate('Emitir como Pessoa Física ou PJ?')}</h2>


                <SelectSimple index={10} style={{ zIndex: 100000 }}
                    setCallback={(value) => setType(value)}
                    options={[
                        { value: 'cpf', label: translate('Pessoa Física') },
                        {
                            value: 'cnpj',
                            label: translate('Entidade / Pessoa Jurídica'),
                        },
                    ]}
                    name="document_type"

                    label={translate('Emitir como')}
                />

                {type === 'cnpj' ? <>

                    <Input type='text' name='company' placeholder='Razão social' />
                    <MaskedInput mask='99.999.999/9999-99' type='text' name='company_document_number' placeholder='CNPJ' />



                </> : <></>}

            </div>

            <p style={{ fontSize: '12px', textAlign: 'center', width: '100%', margin: '10px 0px' }}>{translate('Ao me inscrever, afirmo que li e concordo com a política de privacidade e os termos de cancelamento e reembolso desta plataforma.')}</p>
            <aside style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', alignItems: 'center' }}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>



            <button className='greenButton2 buttonColored' type="submit">
                {translate('Finalizar')}

            </button>

        </Form>
    </>

};
export default PaymentMethodForm;

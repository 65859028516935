import React, { useEffect, useState } from 'react';
import { addHours, getDate, getDay, getMonth } from "date-fns"
import { monthNumber, simpleDateBR, simpleDateBRWithHour, simpleDateUS } from "../../../../../../utils/date";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Chart from '../../../../../../components/ChartModule';
import { FaCalendar, FaChartBar, FaChartPie, FaCheck, FaCreditCard, FaFileExcel, FaFilter, FaTicketAlt } from 'react-icons/fa';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInputSearch';
import { useModal } from '../../../../../../hooks/Modal';
import { FiFilter } from 'react-icons/fi';
import { convertToXLSX } from '../../../../../../utils/download';
import api from '../../../../../../services/api';
import { string } from 'yup';
import { useParams } from 'react-router-dom';
interface IProps {
    sales?: Array<Record<string, any>>,
    salesProducts?: Array<Record<string, any>>,
    students?: Array<Record<string, any>>,
    products?: Array<Record<string, any>>,
}

interface IOption {
    id: string;
    label: string;
    value: string;
}


const convert = (list: Array<Record<string, any>>, group = false) => {

    const items: Array<Array<string>> = [];


    if (group) {
        items.push(['Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Indicado por']);
        list?.map(item => {

            items.push([item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', item?.seller_hash]);

        })
    }
    else {
        items.push(['Produto', 'Transferido?', 'Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Indicado por']);
        list?.map(item => {

            items.push([item?.product, item?.haveClientID, item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', item?.seller_hash]);

        })
    }


    convertToXLSX(items);

}

const ListTicketsByDay: React.FC<IProps> = ({ sales, salesProducts = [], students, products = [] }) => {




    const list = salesProducts?.reduce((prev, item) => {

        const approvedDate = simpleDateBR(item?.approved_at);

        const index = prev?.findIndex(i => i?.date === approvedDate);

        if (index >= 0) {
            prev[index].quantity += 1;
        }
        else {
            prev.push({ date: approvedDate, quantity: 1 })
        }

        return prev;

    }, []);



    const sortedList = list;


    return <>

        <h2 style={{ margin: '0px', padding: '0px 0px' }}>Ingressos com pagamento confirmado.</h2>
        <p style={{ fontSize: '12px', marginBottom: '10px' }}>Ordenados pela data de confirmação de pagamento.</p>
        <table className="tableRef" style={{ width: '100%', maxWidth: '100%', margin: '0px' }}>
            <tr><th>Data</th><th>Quantidade ({salesProducts?.length})</th></tr>
            {sortedList?.map(item => {
                return <tr key={`resumo-vendas-${item?._id?.toString()}`}>
                    <td>
                        {item?.date}
                    </td>
                    <td>
                        {item?.quantity}
                    </td>
                </tr>
            })}

        </table>


    </>


}

export default ListTicketsByDay;
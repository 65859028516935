import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../SponsorsPage';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import RodapeLP from '../Home/modules/RodapeLP';
import Whatsapp from '../../components/Whatsapp';
import { ContactIcons } from 'src/components/MenuOld';
import { getYear } from 'date-fns';
import { useConfiguration } from 'src/hooks/Configuration';
import VideoBackground from 'src/components/VideoBackground';

const FooterLayout: React.FC = ({ children }) => {

  const { configuration } = useConfiguration();
  return (<><div style={{ position: 'relative', zIndex: 0, display: 'block' }}>

    {/* <ImageSliderBackground slides={['/apoio/fundo1.jpg', '/apoio/fundo2.jpg', '/apoio/fundo3.jpg']} /> */}
    <div style={{ width: '100%', borderTop: '5px solid #c60a0a', marginTop: '-3px' }} />

    <div tabIndex={0} className='gap-bg column ' style={{ position: 'relative', minHeight: '10vh', padding: '0px 15px', background: '#fff', zIndex: 900000 }}>

      <div className='full-width ' style={{ zIndex: 1 }}>


        <Sponsors hashReference='portal-bariatric-channel' />

      </div>



    </div>

    <div className='row-to-column max-1400 full-width' style={{ zIndex: 2, position: 'relative', justifyContent: 'space-between', margin: '15px auto', paddingBottom: '10px' }}>
      <p style={{ color: '#fff', fontSize: '14px', padding: '10px' }} className="text-center-mobile">{getYear(new Date())} - © Bariatric Channel - Todos os direitos reservados.</p>
      <ContactIcons color="#fff" />
    </div>
  </div>
    {configuration?.theme_data?.video_background && window?.location?.pathname?.indexOf('dashboard') < 0 ? <div className='pattern' style={{ background: "rgb(0,50,100)", position: 'fixed', top: '0px', left: '0px', height: '100vh', width: '100%', zIndex: -1 }}>
      <VideoBackground style={{ opacity: 0.5 }} path={configuration?.theme_data?.video_background} />
    </div> : <></>}
  </>
  );
};

export default FooterLayout;

import React from 'react';
import { useSearch } from '../../../../hooks/Search';
import { useAuth } from '../../../../hooks/Auth';
import { urlWebsite } from '../../../../config';
import { Container } from './styles';
import { FaBook, FaCalendar, FaCalendarAlt, FaCalendarCheck, FaCertificate, FaChartArea, FaChartBar, FaEdit, FaEye, FaFilePdf, FaGift, FaGrinStars, FaHatWizard, FaHeart, FaHeartbeat, FaHome, FaIdBadge, FaImages, FaLayerGroup, FaLock, FaMicrophone, FaMobile, FaPaintBrush, FaStarAndCrescent, FaTable, FaTablet, FaTicketAlt, FaUser, FaVideo } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import lineModules from '../../core/components/lineModules';
import { FiCalendar, FiEye, FiFile, FiImage, FiLayers, FiList, FiMenu, FiSliders, FiSpeaker, FiStar } from 'react-icons/fi';

import Resume from '../Resumo';
import { useModal } from '../../../../hooks/Modal';
import { useConfiguration } from '../../../../hooks/Configuration';
import { AiFillFormatPainter } from 'react-icons/ai';
import { string } from 'yup';
import { RiPriceTag2Fill } from 'react-icons/ri';
import UpdateEventTheme from '../Events/modules/updateTheme';

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const ResumeEvent: React.FC = () => {


  const { changeSearchOrder } = useSearch();
  const { user } = useAuth();
  const { addModal } = useModal();
  const { configuration } = useConfiguration();
  const { projectHash, eventHash } = useParams<Record<string, any>>();



  return <Container>




    <div className='project-line' >

      <div >
        <img src={`/apoio/eventos.jpeg`} />
      </div>
      <div className='project-line-content'>
        <h2>Evento: {configuration?.current_event_id_response?.title}</h2>
        <div className='project-line-icons'>

          <Link to={`/manager/home/events`}><FiCalendar title="Gerenciar Datas" /></Link>
          <Link to={`/manager/home/events`}><FiImage title="Gerenciar Imagens" /></Link>
          <Link to={`/manager/home/events`}><AiFillFormatPainter title="Gerenciar Temas" /></Link>

          <Link to={`/manager/home/events`}><FiMenu title="Menu" /></Link>
        </div>
      </div>

    </div>

    <div className='project-line' >

      <div >
        <img src={`/apoio/eventos.jpeg`} />
      </div>
      <div className='project-line-content'>
        <h2>Cadastros e Relatórios</h2>
        <div className='project-line-icons'>
          <Link to={`/manager/students2/${projectHash}/${eventHash}`}><FaUser title="Visualizar Cadastros" /></Link>

          <Link to={`/manager/reportSubscriptions`}><FaChartArea title="Relatórios de inscritos" /></Link>
          <Link to={`/manager/reportParticipants`}><FaChartBar title="Relatórios de participantes" /></Link>

          <Link to={`/manager/reportCSAT`}><FaHeartbeat title="Relatórios de Satisfação" /></Link>





        </div>
      </div>

    </div>
    <div className='project-line' >
      <div >
        <img src={`/apoio/eventos.jpeg`} />
      </div>
      <div className='project-line-content'>
        <h2>Inscrições, Vendas e Produtos</h2>
        <div className='project-line-icons'>
          <Link to={`/manager/sale/${projectHash}/${eventHash}`}><FaEye title="Visualizar Vendas" /></Link>

          <Link to={`/manager/product/${projectHash}/${eventHash}`}><FaTicketAlt title="Produtos vendidos" /></Link>
          <Link to={`/manager/courtesy/${projectHash}`}><FaGift title="Cortesias" /></Link>
          <Link to={`/manager/coupons/${projectHash}`}><RiPriceTag2Fill title="Cupons de desconto" /></Link>
        </div>
      </div>

    </div>
    <div className='project-line' >
      <div >
        <img src={`/apoio/eventos.jpeg`} />
      </div>
      <div className='project-line-content'>
        <h2>Landing Page</h2>
        <div className='project-line-icons'>

          <Link to={`/manager/speakersPosition/${projectHash}/${eventHash}`}><FaMicrophone title="Bloco de Palestrantes" /></Link>
          <Link to={`/manager/speaker/${projectHash}`}><FiStar title="Palestrantes" /></Link>
          <Link to={`/manager/program/${projectHash}/${eventHash}`}><FiCalendar title="Programação" /></Link>
          <Link to={`/manager/filegallery/${projectHash}`}><FaFilePdf title="Arquivos de apoio" /></Link>
          <Link to={`/manager/sponsor/${projectHash}/${eventHash}`}><FiImage title="Patrocinadores" /></Link>
          <Link to={`/manager/eventsPages/${projectHash}`}><FiLayers title="Páginas avulsas" /></Link>
          <aside><UpdateEventTheme /></aside>

        </div>
      </div>

    </div>
    <div className='project-line' >
      <div >
        <img src={`/apoio/eventos.jpeg`} />
      </div>
      <div className='project-line-content'>
        <h2>Palestras e Cursos</h2>
        <div className='project-line-icons'>



          <Link to={`/manager/lesson/${projectHash}/${eventHash}`}><FaLayerGroup title="Palestras" /></Link>
          <Link to={`/manager/course/${projectHash}/${eventHash}`}><FaHatWizard title="Cursos" /></Link>
          <Link to={`/manager/slides/${projectHash}/${eventHash}`}><FaImages title="Slides de Sala" /></Link>

        </div>
      </div>
    </div>

    <div className='project-line' >
      <div >
        <img src={`/apoio/eventos.jpeg`} />
      </div>
      <div className='project-line-content'>
        <h2>Certificados, Pesquisas e Exames</h2>
        <div className='project-line-icons'>




          <Link to={`/manager/certificate/${projectHash}/${eventHash}`}><FaCertificate title="Certificados" /></Link>
          <Link to={`/manager/certificateusers/${projectHash}/${eventHash}`}><FaLayerGroup title="Certificados Gerados" /></Link>
          <Link to={`/manager/satisfaction/${projectHash}/${eventHash}`}><FaHeart title="Pesquisa de satisfação" /></Link>
          <Link to={`/manager/exam/${projectHash}/${eventHash}`}><FaBook title="Exame" /></Link>
        </div>
      </div>
    </div>


  </Container >





}

export default ResumeEvent;
